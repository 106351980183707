import React, { FC } from 'react';
import styled from '@emotion/styled';
import { Theme, css } from '@emotion/react';
import { Button } from '@sumup/circuit-ui';

import { MenuItem as MenuItemType } from '../../interfaces';

import Link from '~/shared/components/Link';
import {
  STOREFRONT_URL_DEV,
  STOREFRONT_URL_PROD,
} from '~/domains/shop/services/purchasable-client/constants';

export interface MobileStickyCtaProps {
  ctaItem?: MenuItemType;
  isSticky?: boolean;
  shopIntegration?: boolean;
}

const containerStyles = ({
  theme,
  isSticky,
  shopIntegration,
}: {
  theme?: Theme;
  isSticky: boolean;
  shopIntegration: boolean;
}) => css`
  display: none;

  ${theme.mq.untilMega} {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: ${theme.spacings.kilo};
    background-color: var(--cui-bg-normal);
    box-shadow: 0 -2px 2px 0 rgba(12, 15, 20, 0.07);
    z-index: ${theme.zIndex.underCircuitUIModal};

    ${shopIntegration && 'column-gap: var(--cui-spacings-giga);'}
  }

  ${isSticky &&
  `
    animation-name: slidein;
    animation-duration: 0.3s;
    @keyframes slidein {
      from {
        transform: translateY(100%);
      }
      to {
        translateY(0);
      }
    }
  `}
`;
const Container = styled('div')(containerStyles);

export const MobileStickyCta: FC<MobileStickyCtaProps> = ({
  ctaItem,
  isSticky = false,
  shopIntegration = false,
}) => {
  if (!ctaItem) {
    return null;
  }

  const hasCtaUrl = !!(ctaItem?.label && ctaItem?.destination?.url);
  const hasShopUrlInCta = [STOREFRONT_URL_PROD, STOREFRONT_URL_DEV].some(
    (storefrontUrl) => ctaItem?.destination?.url?.includes(storefrontUrl),
  );
  const shouldShowCtaLink = shopIntegration
    ? hasCtaUrl && !hasShopUrlInCta
    : hasCtaUrl;
  const showTableOfContentsCta = isSticky && shouldShowCtaLink;

  return (
    <>
      {showTableOfContentsCta && (
        <Container
          data-selector="mobile-sticky-cta"
          isSticky={isSticky}
          shopIntegration={shopIntegration}
        >
          <Link
            href={ctaItem.destination?.url}
            trackingId=""
            trackingContentEntry={{}}
          >
            <Button variant="primary" stretch>
              {ctaItem.label}
            </Button>
          </Link>
        </Container>
      )}
    </>
  );
};
