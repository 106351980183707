import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { useRouter } from 'next/router';
import includes from 'lodash/fp/includes';
import isEmpty from 'lodash/fp/isEmpty';

import * as EmbeddedVariableService from './EmbeddedVariableService';
import { VARIABLES, TEXT_FORMATS, TEXT_SIZES } from './constants';

import * as Logger from '~/shared/services/logger';
import ProductsContext from '~/shared/providers/ProductsContext';
import FeesContext from '~/shared/providers/FeesContext';
import SiteContext from '~/shared/providers/SiteContext';

const getTextFormatStyles = (textFormats = []) => {
  const selectedFormats = Object.values(TEXT_FORMATS).reduce(
    (memo, supportedFormat) => {
      if (includes(supportedFormat, textFormats)) {
        // eslint-disable-next-line no-param-reassign
        memo[supportedFormat] = true;
      }
      return memo;
    },
    {},
  );

  return ({ theme }) => css`
    ${selectedFormats[TEXT_FORMATS.BOLD] &&
    css`
      font-weight: ${theme.fontWeight.bold};
    `}
    ${selectedFormats[TEXT_FORMATS.ITALIC] &&
    css`
      font-style: italic;
    `}
    ${selectedFormats[TEXT_FORMATS.STRIKETHROUGH] &&
    css`
      text-decoration: line-through;
    `}
  `;
};
const TEXT_SIZE_RULES = {
  [TEXT_SIZES.HEADLINE_ONE]: ({ theme }) => css`
    ${theme.typography.headline.one}
  `,
  [TEXT_SIZES.HEADLINE_TWO]: ({ theme }) => css`
    ${theme.typography.headline.two}
  `,
  [TEXT_SIZES.HEADLINE_THREE]: ({ theme }) => css`
    ${theme.typography.headline.three}
  `,
  [TEXT_SIZES.HEADLINE_FOUR]: ({ theme }) => css`
    ${theme.typography.headline.four}
  `,
  [TEXT_SIZES.BODY_ONE]: ({ theme }) => css`
    ${theme.typography.body.one}
  `,
  [TEXT_SIZES.BODY_TWO]: ({ theme }) => css`
    ${theme.typography.body.two}
  `,
};

export const getStyledWrapper = (textSize, textFormats = []) => {
  const textFormatStyles = getTextFormatStyles(textFormats);
  const textSizeStyles = TEXT_SIZE_RULES[textSize];
  const StyledWrapper = styled('span')(textFormatStyles, textSizeStyles);

  return StyledWrapper;
};

/**
 * Embed a dynamic string, such as a product price, fee or support email.
 */
function EmbeddedVariable({ variable, textSize, textFormats, ...rest }) {
  const { products = {} } = useContext(ProductsContext);
  const fees = useContext(FeesContext);
  const site = useContext(SiteContext);
  const router = useRouter();
  const context = { ...rest, products, fees, site, router };

  if (!variable) {
    return null;
  }

  const handlerFn = EmbeddedVariableService.handlers[variable];

  if (!handlerFn) {
    Logger.warn('[RichText/EmbeddedVariable]', `No handler for ${variable}`);
    return null;
  }

  const component = handlerFn(context);
  const hasCustomStyling = !isEmpty(textFormats) || !isEmpty(textSize);

  if (component && hasCustomStyling) {
    const StyledWrapper = getStyledWrapper(textSize, textFormats);

    return <StyledWrapper>{component}</StyledWrapper>;
  }

  return component || null;
}

EmbeddedVariable.propTypes = {
  variable: PropTypes.oneOf(Object.values(VARIABLES)).isRequired,
  product: PropTypes.object,
  textFormats: PropTypes.arrayOf(PropTypes.oneOf(Object.values(TEXT_FORMATS))),
  textSize: PropTypes.oneOf(Object.values(TEXT_SIZES)),
  queryParameterName: PropTypes.string,
  queryParameterDefaultValue: PropTypes.string,
};

/**
 * @component
 */
export default EmbeddedVariable;
