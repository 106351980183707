import React, { FC } from 'react';
import styled from '@emotion/styled';
import { Theme, css } from '@emotion/react';
import { Anchor, Body, BodyLarge } from '@sumup/circuit-ui';

import { TableOfContentsMenu as TableOfContentsMenuType } from '../../interfaces';

import DesktopTableOfContents from './components/DesktopTableOfContents';
import MobileTableOfContents from './components/MobileTableOfContents';
import { DATA_SELECTOR } from './constants';

import dataSelector from '~/shared/util/data-selector';
import LogoBranded from '~/shared/components/LogoBranded';
import Link from '~/shared/components/Link';

const wrapperStyles = ({
  theme,
  isSticky,
}: {
  theme?: Theme;
  isSticky: boolean;
}) => css`
  position: relative;
  z-index: calc(${theme.zIndex.navigation} - 1);
  background-color: var(--cui-bg-normal);
  border-bottom: ${theme.borderWidth.kilo} solid var(--cui-border-subtle);
  ${isSticky &&
  `
    position: fixed;
    top: 0;
    width: 100%;
    animation-name: navslidein;
    animation-duration: 0.3s;
    @keyframes navslidein {
      from {
        transform: translateY(-100%);
      }
      to {
        translateY(0);
      }
    }
  `}
`;
const Wrapper = styled('div')(wrapperStyles);

const containerStyles = ({ theme }: { theme: Theme }) => css`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  padding: 0 ${theme.spacings.giga};
  margin: 0 auto;
  position: relative;
  height: ${theme.spacings.nav.mobile};
  grid-gap: ${theme.spacings.giga};

  ${theme.mq.tera} {
    padding: 0;
    width: ${theme.maxWidth};
    height: ${theme.spacings.nav.desktop};
  }

  ${theme.mq.untilMega} {
    grid-gap: 0;
  }
`;
const Container = styled('div')(containerStyles);

const labelStyles = ({
  theme,
  isMobileMenuOpen,
}: {
  theme?: Theme;
  isMobileMenuOpen?: boolean;
}) => css`
  cursor: pointer;
  height: 100%;
  align-items: center;
  display: flex;
  transition:
    color 0.1s ease-in-out,
    padding 0.1s ease-in-out;
  ${theme.mq.untilMega} {
    ${theme.typography.headline.four}
    ${isMobileMenuOpen &&
    `
      padding-left: ${theme.spacings.giga};
    `};
    ${!isMobileMenuOpen &&
    `
      color: var(--cui-fg-subtle);
    `};
  }
`;
const Label = styled(Body)(labelStyles);
const StyledAnchor = styled(Anchor)(
  () => css`
    color: var(--cui-fg-subtle);
    text-decoration: none;
  `,
);

const logoLabelContainerStyles = ({
  theme,
  isSticky,
}: {
  theme?: Theme;
  isSticky?: boolean;
}) => css`
  ${isSticky &&
  `
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${theme.spacings.kilo};`}
`;

const LogoLabelContainer = styled('div')(logoLabelContainerStyles);

const logoBrandedStyles = ({ theme }: { theme: Theme }) => css`
  display: none;

  ${theme.mq.giga} {
    display: block;
  }
`;

const StyledLogoBranded = styled(LogoBranded)(logoBrandedStyles);

interface TableOfContentsMenuProps extends TableOfContentsMenuType {
  isMobileMenuOpen?: boolean;
  toggleMobileMenu?: () => void;
  handleBrandClick?: () => void;
  logoTrackingContentEntry?: Record<string, string>;
  isSticky?: boolean;
  shopIntegration?: boolean;
  labelUrl?: string;
}

export const TableOfContentsMenu: FC<TableOfContentsMenuProps> = ({
  label = '',
  labelUrl = '',
  items = [],
  ctaItem,
  isMobileMenuOpen = false,
  toggleMobileMenu = () => {},
  handleBrandClick = () => {},
  logoTrackingContentEntry,
  isSticky = false,
  shopIntegration = false,
}) => {
  if (!label.length) {
    return null;
  }

  const handleLabelClick = () => {
    toggleMobileMenu();
  };

  return (
    <Wrapper
      isSticky={isSticky}
      data-selector={dataSelector('wrapper', DATA_SELECTOR)}
    >
      <Container>
        <LogoLabelContainer isSticky={isSticky}>
          {isSticky && (
            <StyledLogoBranded
              isShortLogo
              primaryLogoLinksToHomepage
              onClick={handleBrandClick}
              trackingContentEntry={logoTrackingContentEntry}
            />
          )}
          <Label
            variant="highlight"
            data-selector={dataSelector('label', DATA_SELECTOR)}
            isMobileMenuOpen={isMobileMenuOpen}
            onClick={handleLabelClick}
          >
            {labelUrl ? (
              <Link href={labelUrl} trackingId="" trackingContentEntry={{}}>
                <StyledAnchor data-selector="label-url">
                  <BodyLarge as="span">{label}</BodyLarge>
                </StyledAnchor>
              </Link>
            ) : (
              label
            )}
          </Label>
        </LogoLabelContainer>
        <DesktopTableOfContents
          items={items}
          ctaItem={ctaItem}
          isSticky={isSticky}
          shopIntegration={shopIntegration}
        />
        <MobileTableOfContents
          items={items}
          isOpen={isMobileMenuOpen}
          toggleMobileMenu={toggleMobileMenu}
        />
      </Container>
    </Wrapper>
  );
};
