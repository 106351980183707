import { IncomingMessage, ServerResponse } from 'http';
import { Socket } from 'net';
import { ParsedUrlQuery } from 'querystring';

import Router from 'next/router';
import getConfig from 'next/config';
import { GetServerSidePropsContext, NextPageContext } from 'next/types';
import reduce from 'lodash/fp/reduce';
import includes from 'lodash/fp/includes';
import repeat from 'lodash/fp/repeat';
import get from 'lodash/fp/get';
import isEmpty from 'lodash/fp/isEmpty';
import isNumber from 'lodash/fp/isNumber';
import { getAll as getAllCookies } from 'es-cookie';
import cookieParser from 'cookie';
import { types as OptimizelyTypes } from '@sumup/optimizely-full-stack';

import { ProductType } from '../types/shared';

import * as Url from './url';
import { formatUrl } from './website-backend/url-formatter';

import api from '~/shared/services/api';
import isServer from '~/shared/util/is-server';
import { hasContent } from '~/shared/util/rich-text';
import {
  DAYS,
  CACHE_CONTROL_HEADER,
  VERCEL_CDN_CACHE_MAX_AGE,
  VERCEL_CDN_CACHE_REVALIDATION_THRESHOLD,
  VERCEL_CDN_CACHE_MAX_AGE_PREVIEW,
  VERCEL_CDN_CACHE_REVALIDATION_THRESHOLD_PREVIEW,
  VERCEL_CDN_CACHE_MAX_AGE_SHOP_INTEGRATION,
  VERCEL_CDN_CACHE_REVALIDATION_THRESHOLD_SHOP_INTEGRATION,
  VERCEL_PASSWORD_PROTECTION_COOKIE_NAME,
  USE_WEBSITE_BACKEND_REPLACEMENT_PARAM,
} from '~/shared/constants';
import * as ENVIRONMENTS from '~/shared/constants/environments';
import {
  mapVariations,
  getVariationComponent,
} from '~/shared/services/optimizely/OptimizelyVariationsService';
import isExpiredDate from '~/shared/util/is-expired-date';
import { formatCurrency, getCurrencyFormat } from '~/shared/services/currency';
import { CURRENCY_SYMBOLS } from '~/shared/constants/currency';
import {
  AVAILABLE_LOCALES_ONE_DOMAIN,
  DEFAULT_LOCALE_KEY,
  DEFAULT_LOCALE_ONE_DOMAIN,
} from '~/shared/constants/locales';
import { RequestType } from '~/shared/providers/RequestContext/RequestContext';
import { WB_REPLACEMENT_SITE_FEATURE_TOGGLE } from '~/shared/services/optimizely/constants';
import { getOptimizelyData } from '~/shared/services/optimizely/optimizely-data';
import {
  LegacyExtraLevelNavigation,
  LegacyNavigation,
  LegacyNavigationLinkBase,
} from '~/shared/api-controllers/shared/transformers/legacy-navigation';
import { WithOptimizelyVariations } from '~/shared/api-controllers/shared/transformers/optimizely-variation';
import {
  FlattenedFooter,
  FlattenedInfoBanner,
} from '~/shared/services/contentful';
import { isNonLocalizedPathname } from '~/shared/services/pathnames';

const { publicRuntimeConfig = {} } = getConfig();
const { environment, isVercelDeployment } = publicRuntimeConfig;

interface KeyValueMap {
  [key: string]: string | number | boolean | null;
}

export interface ExtendedSocket extends Socket {
  encrypted?: boolean;
}

export interface ServerRequest extends IncomingMessage {
  cookies?: KeyValueMap;
  secure?: boolean;
  connection: ExtendedSocket;
}

export interface BaseUrl {
  host?: string;
  hostname?: string;
  protocol?: string;
}

export type Context = {
  req?: ServerRequest;
} & NextPageContext &
  GetServerSidePropsContext;

export interface CustomParsedUrl extends URL {
  query?: ParsedUrlQuery;
}

export interface Product {
  hasPromo?: boolean;
  productId?: string;
  termsConditions?: string;
  termsConditionsPromo?: string;
  original?: {
    expirationDate?: string;
  };
}

// we need to ensure compatability of contexts when we use getServerSideProps (e.g. as website-backend replacement)
// because most of the website code works with NextPageContext from getInitialProps.
// https://nextjs.org/docs/pages/building-your-application/data-fetching/get-server-side-props
// https://nextjs.org/docs/pages/api-reference/functions/get-initial-props
//
// NOTE: we can get rid of compat, once we move completely to website-backend replacement and feature toggles will be considered stable
// https://sumupteam.atlassian.net/wiki/spaces/DEV/pages/22497460348/Website-backend+page+API+replacement#1.-Maximum-separation
export function getNextjsContextCompat(
  getServerSidePropsContext: GetServerSidePropsContext,
): Context {
  return {
    ...getServerSidePropsContext,
    asPath: getServerSidePropsContext.resolvedUrl,
    locale: getOneDomainLocaleFromPathname(
      getServerSidePropsContext.resolvedUrl,
    ),
  } as Context;
}

export function getBaseUrlProtocol(request?: ServerRequest) {
  if (isVercelDeployment) {
    return 'https:';
  }

  const { headers = {}, secure, connection } = request || {};
  const isEncrypted = secure || connection?.encrypted;
  const proto = isEncrypted ? 'https' : 'http';
  const xForwardedProto = Array.isArray(headers['x-forwarded-proto'])
    ? headers['x-forwarded-proto'][0]
    : headers['x-forwarded-proto'];
  const protoHeader = xForwardedProto || proto;
  // Note: X-Forwarded-Proto is normally only ever a single value, but this is to be safe
  const protoIndex = protoHeader.indexOf(',');
  const actualProto =
    protoIndex !== -1
      ? protoHeader.substring(0, protoIndex).trim()
      : protoHeader.trim();

  return `${actualProto}:`;
}

export function getBaseUrl(ctx?: {
  query: ParsedUrlQuery;
  req: ServerRequest;
}) {
  if (isServer) {
    const { query, req } = ctx || {};
    const { headers = {} } = req || {};
    const { host } = headers;
    const hostname =
      (Array.isArray(query.hostname) ? query.hostname[0] : query.hostname) ||
      host;
    const protocol = getBaseUrlProtocol(req);

    return {
      protocol,
      hostname,
      host,
    };
  }

  const { protocol, hostname, host } = Url.parse(window.location.origin);
  return { protocol, hostname, host };
}

export function fetchSite(ctx: Context, baseUrl: BaseUrl) {
  const { query = {}, locale = 'en-us' } = ctx || {};
  const urlQuery = Url.cleanApiParams(query);

  const { featureToggles } = getOptimizelyData(query);
  const isWBSiteReplacementEnabled =
    featureToggles[WB_REPLACEMENT_SITE_FEATURE_TOGGLE];
  if (isWBSiteReplacementEnabled) {
    urlQuery[USE_WEBSITE_BACKEND_REPLACEMENT_PARAM] = 'true';
  }

  const finalLocale =
    locale === DEFAULT_LOCALE_KEY ? DEFAULT_LOCALE_ONE_DOMAIN : locale;
  const url = formatUrl(
    `/api/site/${finalLocale}`,
    baseUrl?.protocol,
    baseUrl?.host,
    urlQuery,
  );
  const vercelPasswordProtectionCookie = getVercelPasswordProtectionCookie(ctx);

  return api.getEntry(url, { vercelPasswordProtectionCookie });
}

export function getRequest(
  ctx: {
    asPath?: string;
    resolvedUrl?: string;
    req: ServerRequest;
    query: ParsedUrlQuery;
  },
  baseUrl: BaseUrl,
): RequestType {
  const { req } = ctx || {};
  const { protocol, host, hostname } = baseUrl || {};
  const origin = Url.format({ protocol, host });
  const asPath = ctx.asPath || ctx.resolvedUrl;

  if (isServer) {
    const { query } = ctx;
    const { headers = {} } = req || {};
    const cookiesStr = headers.cookie || '';
    const cookies = cookieParser.parse(cookiesStr);
    const userAgent = headers['user-agent'];
    const { href, pathname } = Url.parse(`${protocol}//${host}${asPath}`);
    return {
      host,
      hostname,
      href,
      origin,
      pathname,
      protocol,
      query,
      userAgent,
      cookies,
      headers,
    };
  }

  const { pathname, query = {} } = Url.parse(asPath) as CustomParsedUrl;
  const href = Url.format({ protocol, host, pathname, query });
  const { userAgent } = window.navigator;
  const cookies = getAllCookies();
  return {
    host,
    hostname,
    href,
    origin,
    pathname,
    protocol,
    query,
    userAgent,
    cookies,
    headers: {},
  };
}

export const handleRedirect = (
  ctx: Context,
  request: RequestType,
  redirectUrl: string,
) => {
  const { res } = ctx;
  if (res) {
    res.writeHead(301, {
      Location: redirectUrl,
    });
    res.end();
    return;
  }

  const parsedUrl = Url.parse(redirectUrl);
  const isInteral = Url.isInternal(parsedUrl, request);

  if (isInteral) {
    Router.replace(redirectUrl);
    return;
  }

  window.location.replace(redirectUrl);
};

export function getFormattedProducts(
  products = {},
  optimizelyExperiments = [],
) {
  return reduce(
    (productsMap, originalProduct) => {
      const product = getVariationComponent(
        originalProduct,
        optimizelyExperiments,
      );

      // eslint-disable-next-line no-param-reassign
      productsMap[product.productId] = product;

      return productsMap;
    },
    {},
    products,
  );
}

function getFootnote(footnoteAsNumber, footnoteCount) {
  return footnoteAsNumber
    ? footnoteCount.toLocaleString()
    : repeat(footnoteCount, '*');
}

export function getFootnoteConfig(footnoteAsNumber = false, footnoteCount = 1) {
  return {
    footnoteAsNumber,
    footnote: getFootnote(footnoteAsNumber, footnoteCount),
  };
}

export function mergeProducts(
  siteProducts = {},
  pageProducts = {},
  activeProducts = [],
  termsOverride = {},
  footnoteAsNumber = false,
): KeyValueMap {
  let footnoteCount = 1;

  return reduce(
    (allProducts, product: Product) => {
      const { productId, termsConditionsPromo, termsConditions } = product;

      const productOverride = pageProducts[productId] || {};
      const hasProductPromo =
        product.hasPromo && !isExpiredDate(product.original?.expirationDate);
      const hasProductOverridePromo =
        productOverride.hasPromo &&
        !isExpiredDate(productOverride.original?.expirationDate);
      const hasPromo = !isEmpty(productOverride)
        ? hasProductOverridePromo
        : hasProductPromo;

      const terms = hasPromo ? termsConditionsPromo : termsConditions;
      const isActive = includes(productId, activeProducts);
      const showProductTerms = isActive && hasContent(terms);
      const showOverrideTerms = !isEmpty(termsOverride);
      const footnote =
        showProductTerms || showOverrideTerms
          ? getFootnote(footnoteAsNumber, footnoteCount)
          : '';
      const shouldIncrementFootnote = showProductTerms && !showOverrideTerms;

      if (shouldIncrementFootnote) {
        footnoteCount += 1;
      }

      // eslint-disable-next-line no-param-reassign
      allProducts[productId] = {
        ...product,
        ...productOverride,
        hasPromo,
        isActive,
        terms,
        showTerms: showProductTerms,
        footnoteConfig: {
          footnote,
          footnoteAsNumber,
        },
      };

      return allProducts;
    },
    {},
    siteProducts,
  );
}

export function getTermsOverride(
  originalSiteTerms,
  originalPageTerms,
  optimizelyExperiments = [],
) {
  const siteTerms = getVariationComponent(
    originalSiteTerms,
    optimizelyExperiments,
  );
  const pageTerms = getVariationComponent(
    originalPageTerms,
    optimizelyExperiments,
  );

  return pageTerms || siteTerms;
}

export function mergeFees(
  siteFees = {},
  partnerProductFees = {},
  pageFees = {},
  optimizelyExperiments = [],
) {
  return {
    ...siteFees,
    ...partnerProductFees,
    ...getVariationComponent(pageFees, optimizelyExperiments),
  };
}

export function getNavigation(
  siteNavigation?:
    | LegacyNavigation
    | WithOptimizelyVariations<LegacyNavigation>,
  pageNavigation?:
    | LegacyNavigation
    | WithOptimizelyVariations<LegacyNavigation>,
  optimizelyExperiments: OptimizelyTypes.OptimizelyExperiment[] = [],
) {
  const originalNavigation = pageNavigation || siteNavigation || {};

  if (isEmpty(originalNavigation)) {
    return {};
  }

  const navigation = getVariationComponent(
    originalNavigation,
    optimizelyExperiments,
  ) as LegacyNavigation;

  navigation.links = getNavigationLinks(
    navigation.links as LegacyNavigationLinkBase[],
    optimizelyExperiments,
    true,
  );

  return navigation;
}

export function getExtraLevelNavigation(
  pageExtraLevelNavigation:
    | LegacyExtraLevelNavigation
    | WithOptimizelyVariations<LegacyExtraLevelNavigation>,
  optimizelyExperiments: OptimizelyTypes.OptimizelyExperiment[] = [],
) {
  const originalExtraLevelNavigation = pageExtraLevelNavigation;

  if (isEmpty(originalExtraLevelNavigation)) {
    return {};
  }

  const extraLevelNavigation = getVariationComponent(
    originalExtraLevelNavigation,
    optimizelyExperiments,
  ) as LegacyExtraLevelNavigation;

  extraLevelNavigation.links = getNavigationLinks(
    extraLevelNavigation.links as LegacyNavigationLinkBase[],
    optimizelyExperiments,
    false,
  );

  return extraLevelNavigation;
}

export function getNavigationLinks(
  links: (
    | LegacyNavigationLinkBase
    | WithOptimizelyVariations<LegacyNavigationLinkBase>
  )[] = [],
  optimizelyExperiments: OptimizelyTypes.OptimizelyExperiment[] = [],
  shouldMapExtraLevelNavigation: boolean = true,
) {
  if (isEmpty(links)) {
    return [] as LegacyNavigationLinkBase[];
  }

  return links.reduce(
    (
      acc: LegacyNavigationLinkBase[],
      originalLink:
        | LegacyNavigationLinkBase
        | WithOptimizelyVariations<LegacyNavigationLinkBase>,
    ) => {
      const link = getVariationComponent(
        originalLink,
        optimizelyExperiments,
      ) as LegacyNavigationLinkBase;

      if (isEmpty(link)) {
        return acc;
      }

      const { links: sublinks } = link;

      link.links = getNavigationLinks(
        sublinks,
        optimizelyExperiments,
        shouldMapExtraLevelNavigation,
      );

      if (
        shouldMapExtraLevelNavigation &&
        !isEmpty(link.extraLevelNavigation)
      ) {
        link.extraLevelNavigation = getExtraLevelNavigation(
          link.extraLevelNavigation,
          optimizelyExperiments,
        );
      }

      acc.push(link);

      return acc;
    },
    [],
  );
}

export function getInfoBanner(
  siteInfoBanner?:
    | FlattenedInfoBanner
    | WithOptimizelyVariations<FlattenedInfoBanner>,
  optimizelyExperiments: OptimizelyTypes.OptimizelyExperiment[] = [],
) {
  const originalInfoBanner = siteInfoBanner;

  if (isEmpty(originalInfoBanner)) {
    return {};
  }

  const infoBanner = getVariationComponent(
    originalInfoBanner,
    optimizelyExperiments,
  ) as FlattenedInfoBanner;

  return infoBanner;
}

export function getHeaderNavigation(
  headerNavigation = {},
  tableOfContents = {},
  hideInfoBanner = false,
) {
  return {
    navigation: headerNavigation,
    tableOfContents,
    hideInfoBanner,
  };
}

export function getFooter(
  siteFooter?: FlattenedFooter | WithOptimizelyVariations<FlattenedFooter>,
  pageFooter?: FlattenedFooter | WithOptimizelyVariations<FlattenedFooter>,
  optimizelyExperiments: OptimizelyTypes.OptimizelyExperiment[] = [],
) {
  const originalFooter = pageFooter || siteFooter || {};

  if (isEmpty(originalFooter)) {
    return {};
  }

  const footer = getVariationComponent(
    originalFooter,
    optimizelyExperiments,
  ) as FlattenedFooter;

  return footer;
}

export function getSecondaryLogo(
  pageSecondaryLogo = {},
  optimizelyExperiments = [],
) {
  const originalSecondaryLogo = pageSecondaryLogo;

  if (isEmpty(originalSecondaryLogo)) {
    return {};
  }

  const secondaryLogo = getVariationComponent(
    originalSecondaryLogo,
    optimizelyExperiments,
  );

  return secondaryLogo;
}

export function getSections(originalSections = [], optimizelyExperiments = []) {
  const sections = mapVariations(originalSections, optimizelyExperiments);

  return sections;
}

export function getCardSchemes(
  siteCardSchemes,
  originalPageCardSchemes,
  optimizelyExperiments = [],
) {
  const pageCardSchemes =
    getVariationComponent(originalPageCardSchemes, optimizelyExperiments) || {};

  return pageCardSchemes.cardSchemes || siteCardSchemes;
}

export function getGroupedCardSchemes(
  siteGroupedCardSchemes,
  originalPageGroupedCardSchemes,
  optimizelyExperiments = [],
) {
  const pageGroupedCardSchemes =
    getVariationComponent(
      originalPageGroupedCardSchemes,
      optimizelyExperiments,
    ) || {};

  return !isEmpty(pageGroupedCardSchemes)
    ? pageGroupedCardSchemes
    : siteGroupedCardSchemes;
}

export function isCallbackAgentAvailaiable(availability?, availableHours?) {
  if (!availability || !availableHours) {
    return false;
  }

  const { timeZone, startDate, endDate } = availableHours;
  const currentDate = new Date();
  const currentDay = currentDate.getDay();

  const currentDateInTimeZone = new Date(
    currentDate.toLocaleString('en-US', {
      timeZone,
    }),
  );

  const currentHours = currentDateInTimeZone.getHours();
  const currentMinutes = currentDateInTimeZone.getMinutes();
  const currentDayName = DAYS[currentDay];

  if (availability.indexOf(currentDayName) === -1) {
    return false;
  }

  const startHours = new Date(startDate).getHours();
  const endHours = new Date(endDate).getHours();
  const startMinutes = new Date(startDate).getMinutes();
  const endMinutes = new Date(endDate).getMinutes();

  // verifies that the user time is in between the given time
  if (
    (startHours < currentHours ||
      (startHours === currentHours && startMinutes < currentMinutes)) &&
    (currentHours < endHours ||
      (currentHours < endHours && currentMinutes < endMinutes))
  ) {
    return true;
  }

  return false;
}

export function mapCallbackWidget(
  pageProps: {
    page?: {
      callbackWidget?: {
        duringAvailableTime?: boolean;
        available?: boolean;
        availability?: string[];
        availableHours?: {
          startDate?: string;
          endDate?: string;
        };
      };
    };
  } = {},
  optimizelyExperiments = [],
) {
  const callbackWidgetProp = get('page.callbackWidget', pageProps) || {};
  const callbackWidget = getVariationComponent(
    callbackWidgetProp,
    optimizelyExperiments,
  );

  if (isEmpty(callbackWidget)) {
    return pageProps;
  }

  const duringAvailableTime = isCallbackAgentAvailaiable(
    callbackWidget.availability,
    callbackWidget.availableHours,
  );

  const { hideWhenUnavailable = true } = callbackWidget;

  return {
    ...pageProps,
    page: {
      ...pageProps.page,
      callbackWidget: {
        ...callbackWidget,
        duringAvailableTime,
        available: duringAvailableTime || !hideWhenUnavailable,
      },
    },
  };
}

export function scrollTopOnWrongAnchor() {
  const { hash } = window.location;
  if (hash && !document.getElementById(hash.substring(1))) {
    window.scrollTo(0, 0);
  }
}

// vercel uses its' own stale while revalidate feature
// httpsz://vercel.com/docs/concepts/edge-network/caching#stale-while-revalidate
export function vercelStaleWhileRevalidate(
  serverResponse: ServerResponse,
  shopIntegration?: boolean,
) {
  if (!isServer) {
    return;
  }

  if (!serverResponse?.setHeader) {
    return;
  }

  const getHeaderValues = () => {
    const isProductionWebsiteConfig = environment === ENVIRONMENTS.PRODUCTION;
    if (isProductionWebsiteConfig) {
      if (shopIntegration) {
        return {
          sMaxAge: VERCEL_CDN_CACHE_MAX_AGE_SHOP_INTEGRATION,
          revalidationThreshold:
            VERCEL_CDN_CACHE_REVALIDATION_THRESHOLD_SHOP_INTEGRATION,
        };
      }
      return {
        sMaxAge: VERCEL_CDN_CACHE_MAX_AGE,
        revalidationThreshold: VERCEL_CDN_CACHE_REVALIDATION_THRESHOLD,
      };
    }
    return {
      sMaxAge: VERCEL_CDN_CACHE_MAX_AGE_PREVIEW,
      revalidationThreshold: VERCEL_CDN_CACHE_REVALIDATION_THRESHOLD_PREVIEW,
    };
  };

  const headerValues = getHeaderValues();
  serverResponse.setHeader(
    CACHE_CONTROL_HEADER,
    `public, s-maxage=${headerValues.sMaxAge}, stale-while-revalidate=${headerValues.revalidationThreshold}`,
  );
}

export function getVercelPasswordProtectionCookie(ctx: Context) {
  const vercelPasswordProtectionToken =
    ctx?.req?.cookies?.[VERCEL_PASSWORD_PROTECTION_COOKIE_NAME];

  if (!vercelPasswordProtectionToken) {
    return undefined;
  }

  return `${VERCEL_PASSWORD_PROTECTION_COOKIE_NAME}=${vercelPasswordProtectionToken};`;
}

export function applyCurrencyOverrideToProducts(
  products,
  currencyCode,
  locale,
) {
  if (!currencyCode) {
    return products;
  }

  return reduce(
    (allProducts, product: Product) => {
      const { productId } = product;
      const { prepend: prependCurrencySymbol } = getCurrencyFormat(
        currencyCode,
        locale,
      );
      const currency = CURRENCY_SYMBOLS[currencyCode];

      // eslint-disable-next-line no-param-reassign
      allProducts[productId] = {
        ...product,
        currencyCode,
        currency,
        prependCurrencySymbol,
      };

      ['price', 'fullPrice', 'promoPrice'].forEach((key) => {
        const originalValue = product.original[key];
        if (isNumber(originalValue)) {
          // eslint-disable-next-line no-param-reassign
          allProducts[productId][key] = formatCurrency(
            originalValue,
            currencyCode,
            locale,
          );
        }
      });

      return allProducts;
    },
    {},
    products,
  );
}

export function applyCurrencyOverrideToFees(fees, currencyCode, locale) {
  if (!currencyCode || !isNumber(fees.original.monthlyCost)) {
    return fees;
  }

  const formattedMonthlyCost = formatCurrency(
    fees.original.monthlyCost,
    currencyCode,
    locale,
  );

  return { ...fees, monthlyCost: formattedMonthlyCost };
}

export function getOneDomainLocaleFromPathname(pathname: string) {
  if (isNonLocalizedPathname(pathname)) {
    return DEFAULT_LOCALE_ONE_DOMAIN;
  }

  const [, firstChunk] = pathname?.split('/') || [];
  const isFirstChunkAnExactLocale = AVAILABLE_LOCALES_ONE_DOMAIN.some(
    (locale) => locale.toLowerCase() === firstChunk.toLowerCase(),
  );

  return isFirstChunkAnExactLocale
    ? firstChunk.toLowerCase()
    : DEFAULT_LOCALE_KEY;
}

export function getPartnerProductData(
  partnerProduct,
  routePathname = '',
  locale = '',
) {
  const pathnameWithoutLocale = routePathname
    .replace(locale, '')
    .replace(/\//g, '');
  const excludedRoutes = partnerProduct?.excludedRoutes || [];
  if (excludedRoutes.includes(pathnameWithoutLocale)) {
    return {
      fees: {},
      queryParams: {},
    };
  }

  const isfeeCampaignPromoActive = !isExpiredDate(
    partnerProduct?.feeCampaignPromoTokenExpirationDate,
  );

  const creditCardFee =
    (isfeeCampaignPromoActive && partnerProduct.creditFeePromo) ||
    partnerProduct?.creditFee;

  const debitCardFee =
    (isfeeCampaignPromoActive && partnerProduct.debitFeePromo) ||
    partnerProduct?.debitFee;

  const feeCampaignToken =
    (isfeeCampaignPromoActive && partnerProduct.feeCampaignPromoToken) ||
    partnerProduct?.feeCampaignToken;

  const isNewPromoCodeActive = !isExpiredDate(
    partnerProduct?.newPromoCodeExpirationDate,
  );

  const promoCode =
    (isNewPromoCodeActive && partnerProduct?.newPromoCode) ||
    partnerProduct?.genericPromoCode;

  const fees: { creditCardFee?: string; debitCardFee?: string } = {};
  if (creditCardFee) {
    fees.creditCardFee = creditCardFee;
  }
  if (debitCardFee) {
    fees.debitCardFee = debitCardFee;
  }

  // eslint-disable-next-line camelcase
  const queryParams: { fcam_rc?: string; prc?: string } = {};
  if (feeCampaignToken) {
    queryParams.fcam_rc = feeCampaignToken;
  }
  if (promoCode) {
    queryParams.prc = promoCode;
  }

  return {
    fees,
    queryParams,
  };
}

export function applyComputedFieldsOverride(
  products: Record<string, ProductType>,
) {
  return Object.keys(products).reduce((acc, key) => {
    const product = products[key];
    // override the current price fields with the computed price fields
    if (product.computedPrice) {
      product.price = product.computedPrice;
      product.fullPrice = product.computedFullPrice;

      product.original.price = product.original.computedPrice;
      product.original.fullPrice = product.original.computedFullPrice;
    }

    if (product.computedPromotion?.promoPrice) {
      product.promoPrice = product.computedPromotion.formattedPromoPrice;
      product.hasPromo = product.computedPromotion.hasPromo;

      product.original.promoPrice = product.computedPromotion.promoPrice;
    }

    return {
      ...acc,
      [key]: product,
    };
  }, {});
}
