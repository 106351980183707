import React, { Fragment } from 'react';
import { Block, Document, Inline } from '@contentful/rich-text-types';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';

import api from '~/shared/services/api';
import { pagePropsPropTypes } from '~/shared/util/shared-prop-types';
import captureException from '~/shared/util/capture-exception';
import { BACKGROUNDS, TYPES } from '~/shared/constants/sections';
import { formatBackendUrl } from '~/shared/services/website-backend/url-formatter';
import * as Logger from '~/shared/services/logger';
import * as Url from '~/shared/services/url';
import { getStatusCodeFromError } from '~/shared/services/errors';
import Meta from '~/shared/components/Meta';
import Navigation from '~/shared/components/Navigation';
import Section from '~/shared/components/Sections/components/Section';
import HeroFull from '~/shared/components/Hero/HeroFull';
import Footer from '~/shared/components/Footer';
import usePageMetrics from '~/shared/hooks/use-page-metrics';
import {
  createRichTextDocument,
  createRichTextParagraph,
  createRichTextText,
} from '~/shared/util/rich-text';
import HeaderNavigation from '~/shared/components/HeaderNavigation';
import useOptimizelyData from '~/shared/services/optimizely/use-optimizely-data';
import { NAVIGATION_REVAMP_FEATURE_TOGGLE } from '~/shared/services/optimizely/constants';
import { SiteType, PageType } from '~/shared/types/shared';
import { RequestType } from '~/shared/providers/RequestContext/RequestContext';

export interface ErrorPageProps {
  site: SiteType;
  request: RequestType;
  page: PageType;
  error: {
    title: string;
    subheadline: Block | Inline;
    buttonLabel: string;
    buttonUrl: string;
    statusCode: number;
  };
}

const ErrorPage = (props: ErrorPageProps) => {
  const { featureToggles = {} } = useOptimizelyData();
  const { site = {}, request = {}, page, error } = props;
  const {
    infoBanner = { id: '', contentType: '', name: '' },
    navigation,
    footer,
    headerNavigation,
  } = page;
  const content = error;
  const {
    title = '',
    subheadline,
    buttonLabel = '',
    buttonUrl = '/',
  } = content;
  const background = BACKGROUNDS.BLUE;
  const hasNewNavigation = !!featureToggles[NAVIGATION_REVAMP_FEATURE_TOGGLE];

  usePageMetrics(content.statusCode);

  let heroTitle = title;
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
  let heroSubHeadline = subheadline;
  if (content.statusCode === 404) {
    // The usage of any here was necessary because the type of heroSubheadline was not being inferred correctly
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-explicit-any
    heroTitle = documentToPlainTextString(heroSubHeadline as any);
    heroSubHeadline = {} as Document;
  }

  return (
    <Fragment>
      <Meta request={request} site={site} />
      {hasNewNavigation && headerNavigation ? (
        <HeaderNavigation {...headerNavigation} infoBanner={infoBanner} />
      ) : (
        <Navigation {...navigation} infoBanner={infoBanner} />
      )}
      <Section contentType={TYPES.HERO} spacings={{}} background={background}>
        <HeroFull
          headline={heroTitle}
          subheadline={heroSubHeadline}
          buttonLabel={buttonLabel}
          buttonUrl={buttonUrl}
          background={background}
          badge={undefined}
          index={undefined}
          className={undefined}
          contentType={undefined}
          name={undefined}
          id={undefined}
        />
      </Section>
      <Footer {...footer} />
    </Fragment>
  );
};

ErrorPage.getInitialProps = (ctx) => {
  const { res, query = {}, err = {} } = ctx;
  const statusCode = getStatusCodeFromError(err, res);
  const urlQuery = Url.cleanApiParams(query);

  if (res) {
    res.statusCode = statusCode;
  }

  urlQuery.statusCode = String(statusCode);

  if (err && err.stack) {
    Logger.warn(err.stack);
    captureException(err, ctx);
  }

  const url = formatBackendUrl('/error', urlQuery, ctx.locale);
  if (err?.statusCode === 410 && err?.meta?.isCareers) {
    return {
      error: {
        statusCode: 410,
        title: 'This position is no longer available',
        buttonLabel: 'Explore Open roles',
        buttonUrl: '/careers/positions',
      },
    };
  }

  return api
    .getEntry(url)
    .then((entry) => ({ error: { statusCode, ...entry } }))
    .catch(() => ({
      error: {
        statusCode,
        title: 'An error occurred',
        subheadline: createRichTextDocument([
          createRichTextParagraph([
            createRichTextText('An internal error has occurred.'),
          ]),
        ]),
        buttonLabel: 'Go to the homepage',
      },
    }));
};

ErrorPage.propTypes = pagePropsPropTypes;

export default ErrorPage;
