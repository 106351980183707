import React, {
  Children,
  cloneElement,
  useContext,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import get from 'lodash/fp/get';
import isEmpty from 'lodash/fp/isEmpty';
import isString from 'lodash/fp/isString';
import startsWith from 'lodash/fp/startsWith';
import { useModal } from '@sumup/circuit-ui';
import { useRouter } from 'next/router';

import RequestContext from '~/shared/providers/RequestContext';
import ProductsContext from '~/shared/providers/ProductsContext';
import FeesContext from '~/shared/providers/FeesContext';
import { PageContext } from '~/shared/providers/PageContext';
import SiteContext from '~/shared/providers/SiteContext';
import * as Url from '~/shared/services/url';
import * as LinkService from '~/shared/services/LinkService';
import * as RequestQueryParamsStorage from '~/shared/services/request-query-params-storage';
import { trackingContentEntryPropType } from '~/shared/util/shared-prop-types';
import isServer from '~/shared/util/is-server';
import useOptimizelyData from '~/shared/services/optimizely/use-optimizely-data';
// TODO: Move to shared service or util.
import { isVimeo } from '~/shared/components/Video/components/Vimeo/VimeoService';
// eslint-disable-next-line max-len
import { isYouTube } from '~/shared/components/Video/components/YouTube/YouTubeService';

/**
 * A wrapper around the Next.js Link component with built-in click tracking,
 * query string formatting and promo code forwarding.
 */
function Link({
  children,
  href: hrefProp,
  prefetch: prefetchProp = false,
  onClick = null,
  trackingId,
  trackingLabel = null,
  optimizelyFullStackClickEvents = [],
  shouldTrack = true,
  autoplay = false,
  trackingContentEntry,
  disableSPANavigation = false,
  ...otherProps
}) {
  const { setModal } = useModal();
  const { experiments } = useOptimizelyData();
  const request = useContext(RequestContext);
  const productsConfig = useContext(ProductsContext);
  const fees = useContext(FeesContext);
  const page = useContext(PageContext);
  const site = useContext(SiteContext);
  const originalQuery = { ...request.query };
  const [requestQuery, setRequestQuery] = useState(originalQuery);
  const [localStorageRequestQuery, setLocalStorageRequestQuery] = useState({});
  const { accessibilityLabels = {} } = site;
  const router = useRouter();

  /**
   * Query needs to be updated when query param changes.
   * Otherwise Cloudfront would mess up query params
   * when they're not whitelisted.
   */
  useEffect(() => {
    if (isServer) {
      return;
    }

    const { query } = Url.parse(window.location.href);

    if (!isEmpty(query)) {
      setRequestQuery({ ...query });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const storageRequestQuery = RequestQueryParamsStorage.getAll();

    if (!isEmpty(storageRequestQuery)) {
      setLocalStorageRequestQuery(storageRequestQuery);
    }
  }, []);

  const universalQueryParams = LinkService.getUniversalParams(
    site.universalQueryParams,
    page.universalQueryParams,
    experiments,
  );

  if (isEmpty(children)) {
    return null;
  }

  const child = Children.only(children);
  const updatedRequest = {
    ...request,
    query: { ...originalQuery, ...requestQuery },
  };

  if (isEmpty(hrefProp)) {
    const handleClick = LinkService.getClickHandler(onClick, {
      shouldTrack,
      trackingId,
      trackingLabel,
      optimizelyFullStackClickEvents,
      locale: site.locale,
      trackingContentEntry,
      disableSPANavigation,
      websites: get('websites', site),
    });

    return cloneElement(child, { onClick: handleClick });
  }

  const isOnlyHash = startsWith('#', hrefProp);
  const url = isString(hrefProp)
    ? Url.parse(hrefProp, isOnlyHash ? request.href : request.origin)
    : hrefProp;

  const formattedURL = LinkService.formatURLToOneDomain(
    url,
    request,
    site.locale,
  );

  const query = LinkService.determineQuery(
    formattedURL,
    updatedRequest,
    productsConfig,
    fees,
    page,
    universalQueryParams,
    localStorageRequestQuery,
  );

  const hrefWithQueryParams = LinkService.formatHref(
    formattedURL,
    query,
    updatedRequest,
    site.locale,
  );

  const hrefWithoutQueryParams = LinkService.formatHref(
    formattedURL,
    {},
    updatedRequest,
    site.locale,
  );

  const isInternalUrl = Url.isInternal(formattedURL, updatedRequest);
  const isExternalUrl = LinkService.isExternal(formattedURL);
  const isCurrentPage = Url.isCurrent(formattedURL, updatedRequest);
  const prefetch = isInternalUrl ? prefetchProp : false;
  const internalUrlTag =
    isInternalUrl && !isEmpty(query) ? 'canonical' : undefined;
  const { target, rel } = otherProps;
  const baseProps = {
    'data-track': trackingId,
    'aria-current': isCurrentPage ? 'page' : undefined,
    'rel': rel || (isExternalUrl ? 'noopener noreferrer' : internalUrlTag),
    'target': target || (isExternalUrl ? '_blank' : undefined),
  };

  const actualHref = isInternalUrl
    ? hrefWithoutQueryParams
    : hrefWithQueryParams;

  if (prefetch) {
    router.prefetch(hrefWithQueryParams);
  }

  if (isYouTube(actualHref) || isVimeo(actualHref)) {
    const VideoModal = dynamic(
      () =>
        import(
          /* webpackChunkName: 'component-videomodal' */ '~/shared/components/VideoModal'
        ),
    );
    const Video = dynamic(
      () =>
        import(
          /* webpackChunkName: 'component-video' */ '~/shared/components/Video'
        ),
    );
    const openModal = (event) => {
      event.preventDefault();

      setModal({
        style: { maxWidth: '830px' },
        children: ({ onClose }) => (
          <VideoModal onClose={onClose}>
            <Video
              src={actualHref}
              autoplay={autoplay}
              trackingId={trackingId}
              optimizelyFullStackClickEvents={optimizelyFullStackClickEvents}
              {...otherProps}
            />
          </VideoModal>
        ),
        closeButtonLabel: accessibilityLabels.closeButton,
      });
    };

    return cloneElement(child, {
      ...baseProps,
      href: actualHref,
      onClick: openModal,
      target: '_blank',
    });
  }

  const handleClick = LinkService.getClickHandler(onClick, {
    url: formattedURL,
    shouldTrack,
    trackingId,
    trackingLabel,
    optimizelyFullStackClickEvents,
    locale: site.locale,
    trackingContentEntry,
    websites: get('websites', site),
    router,
    href: hrefWithQueryParams,
    disableSPANavigation,
  });

  return cloneElement(child, {
    ...baseProps,
    href: actualHref,
    onClick: handleClick,
  });
}

Link.propTypes = {
  /**
   * A single element (usually an anchor or button tag) that will be enhanced
   * with the onClick and href attributes.
   */
  children: PropTypes.node.isRequired,
  /**
   * The URL or path to link to. Can be a string or a Node URL object.
   */
  href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /**
   * Specifies where to display the linked URL.
   */
  target: PropTypes.oneOf(['_self', '_blank', '_parent', '_top']),
  /**
   * The tracking id is sent to analytics when the link is clicked.
   */
  trackingId: PropTypes.string,
  /**
   * The tracking label is sent to analytics when the link is clicked.
   */
  trackingLabel: PropTypes.string,
  /**
   * The click events are sent to Optimizely Full Stack when the link is clicked.
   */
  optimizelyFullStackClickEvents: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  ),
  /**
   * Disable sending analytics event when the link is clicked.
   */
  shouldTrack: PropTypes.bool,
  /**
   * Specifies where to display the linked URL.
   */
  prefetch: PropTypes.bool,
  /**
   * The onClick function is called after the click is tracked by analytics and
   * before the route change. Useful e.g. to close a modal or dropdown.
   * Receives the click event as parameter.
   */
  onClick: PropTypes.func,
  /**
   * Autoplay video when link is video
   */
  autoplay: PropTypes.bool,
  /**
   * Information for analytics tracking event.
   */
  trackingContentEntry: trackingContentEntryPropType,

  /*
   * Disables the SPA navigation when selecting an item.
   */
  disableSPANavigation: PropTypes.bool,
};

/**
 * @component
 */
export default Link;
