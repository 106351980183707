import { NextRequest } from 'next/server';
import { NextURL } from 'next/dist/server/web/next-url';

import {
  AVAILABLE_LOCALES_ONE_DOMAIN,
  DEFAULT_LOCALE_KEY,
} from '~/shared/constants/locales';
import { isNonLocalizedPathname } from '~/shared/services/pathnames';

export interface CustomNextUrl extends NextURL {
  originalPathname?: string;
}

// Decision record - https://github.com/sumup/website/blob/master/docs/adr/20240301-manual-nextjs-localization.md
export function getCustomNextUrl(req: NextRequest): CustomNextUrl {
  const url = req.nextUrl.clone();

  // https://github.com/vercel/next.js/discussions/28554
  const shouldIgnoreI18N = isNonLocalizedPathname(url.pathname);
  const customNextUrl = (
    shouldIgnoreI18N
      ? new NextURL(url, {
          nextConfig: {
            trailingSlash: true,
          },
        })
      : new NextURL(url, {
          nextConfig: {
            i18n: {
              // prefixing the default locale with suggested workaround by Next.js
              // https://nextjs.org/docs/advanced-features/i18n-routing#prefixing-the-default-locale
              locales: [DEFAULT_LOCALE_KEY, ...AVAILABLE_LOCALES_ONE_DOMAIN],
              defaultLocale: DEFAULT_LOCALE_KEY,
              localeDetection: false,
            },
            trailingSlash: true,
          },
        })
  ) as CustomNextUrl;

  // NextURL strips locale from pathname and we need to keep it for SEO redirects
  // e.g. requested pathname /xx-XX/foo/bar/
  // `console.log(customNextUrl.pathname)` -> "/foo/bar/"
  // `console.log(customNextUrl.originalPathname)` -> "/xx-XX/foo/bar"
  // `console.log(customNextUrl.locale)` -> "xx-xx"
  customNextUrl.originalPathname = url.pathname;

  return customNextUrl;
}
