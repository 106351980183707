import flatten from 'lodash/fp/flatten';
import get from 'lodash/fp/get';
import isEmpty from 'lodash/fp/isEmpty';
import { BLOCKS, Document } from '@contentful/rich-text-types';

export function createRichTextDocument(content) {
  return {
    data: {},
    nodeType: BLOCKS.DOCUMENT,
    content,
  };
}

export function createRichTextParagraph(content) {
  return {
    data: {},
    nodeType: BLOCKS.PARAGRAPH,
    content,
  };
}

export function createRichTextHeading(value) {
  return {
    data: {},
    content: [
      {
        data: {},
        marks: [],
        value,
        nodeType: 'text',
      },
    ],
    nodeType: BLOCKS.HEADING_1,
  };
}

export function createRichTextSubHeading(value) {
  return {
    data: {},
    content: [
      {
        data: {},
        marks: [],
        value,
        nodeType: 'text',
      },
    ],
    nodeType: BLOCKS.HEADING_3,
  };
}

export function createRichTextHeading5(value) {
  return {
    data: {},
    content: [
      {
        data: {},
        marks: [],
        value,
        nodeType: 'text',
      },
    ],
    nodeType: BLOCKS.HEADING_5,
  };
}

export function createRichTextText(value, marks = []) {
  return {
    marks,
    value,
    data: {},
    nodeType: 'text',
  };
}

export function getRichTextText(doc) {
  const textNodes = doc.content.map((node) => node.content);
  return flatten(textNodes);
}

export function getContent(doc) {
  return get('content[0].content', doc);
}

export function hasContent(doc) {
  const content = getContent(doc);
  return !isEmpty(content);
}

export function isDocumentWithEmptyParagraph(doc?: Document) {
  return Boolean(
    doc &&
      doc.nodeType === 'document' &&
      doc.content &&
      doc.content.length === 1 &&
      doc.content[0].nodeType === 'paragraph' &&
      doc.content[0].content &&
      doc.content[0].content.length === 1 &&
      doc.content[0].content[0].nodeType === 'text' &&
      !doc.content[0].content[0].value,
  );
}
