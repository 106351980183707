import { FC, useContext } from 'react';
import { Body, Button, SubHeadline, Hr } from '@sumup/circuit-ui';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import LineItem from './components/LineItem';

import {
  ShopContext,
  SUMUP_ONE_SKU,
} from '~/domains/shop/providers/ShopProvider';
import { getStorefrontReviewCartLink } from '~/domains/shop/services/purchasable-client';
import isServer from '~/shared/util/is-server';
import SiteContext from '~/shared/providers/SiteContext';
import Link from '~/shared/components/Link';
import { trackGoToCart } from '~/domains/shop/providers/ShopProvider/tracking';

const cartHeaderStyles = () => css`
  background-color: var(--cui-bg-highlight);
  padding: var(--cui-spacings-kilo) var(--cui-spacings-giga);
  position: relative;

  & h2 {
    text-align: center;
  }

  & button {
    position: absolute;
    top: 0;
    right: var(--cui-spacings-byte);
  }
`;
const cartHeaderModalStyles = ({ isModal }: { isModal?: boolean }) =>
  isModal &&
  css`
    padding: var(--cui-spacings-giga) var(--cui-spacings-giga);
    & h2 {
      font-size: var(--cui-typography-body-one-font-size);
    }
  `;
const CartHeader = styled('div')(cartHeaderStyles, cartHeaderModalStyles);

const CartContainer = styled.div`
  padding: var(--cui-spacings-mega) var(--cui-spacings-giga)
    var(--cui-spacings-giga);
`;

const TextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--cui-spacings-byte);
`;

const SubtleText = styled.span`
  font-weight: var(--cui-font-weight-regular);
  color: var(--cui-fg-subtle);
`;

const GrandTotal = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--cui-spacings-giga);
`;

const ProductsContainer = styled.div`
  max-height: 275px;
  overflow-y: auto;
  padding-right: var(--cui-spacings-kilo);
  margin-right: calc(var(--cui-spacings-kilo) * -1);
`;

const EmptyTextWrapper = styled.div`
  text-align: center;
  padding: var(--cui-spacings-byte) 0;
`;

const viewCartButtonStyles = () => css`
  font-size: var(--cui-typography-body-one-font-size);
`;
const ViewCartButton = styled(Button)(viewCartButtonStyles);

export interface CartProps {
  isModal?: boolean;
}

export const Cart: FC<CartProps> = ({ isModal = false }) => {
  const { order, status, removeFromCart } = useContext(ShopContext);
  const { cartLabels, locale } = useContext(SiteContext);

  const isCartLoading = status !== 'idle' && !order;
  const isCartEmpty =
    (!order && status === 'idle') || order?.lineItems?.length === 0;

  const sumUpOneSubscription = order?.lineItems?.find(
    (lineItem) => lineItem?.skuCode === SUMUP_ONE_SKU,
  );
  const hasSumUpOneSubscription = !!sumUpOneSubscription;

  const lineItems = hasSumUpOneSubscription
    ? order?.lineItems?.toReversed()
    : order?.lineItems;

  if (isServer || isCartEmpty || isCartLoading) {
    return (
      <>
        <CartHeader isModal={isModal}>
          <SubHeadline as="h2">{cartLabels.yourCart}</SubHeadline>
        </CartHeader>
        <CartContainer>
          {isCartEmpty ? (
            <EmptyTextWrapper>
              <Body size="two" variant="highlight">
                {cartLabels.emptyCart}
              </Body>
            </EmptyTextWrapper>
          ) : null}
          {isCartLoading ? (
            <EmptyTextWrapper>
              <Body size="two" variant="highlight">
                {cartLabels.loadingLabel}
              </Body>
            </EmptyTextWrapper>
          ) : null}
          <Hr />
          <Button
            variant="primary"
            stretch
            isLoading={status !== 'idle'}
            loadingLabel={cartLabels.loadingLabel}
            disabled
          >
            {cartLabels.viewCart}
          </Button>
        </CartContainer>
      </>
    );
  }

  return (
    <>
      <CartHeader isModal={isModal}>
        <SubHeadline as="h2">{cartLabels.yourCart}</SubHeadline>
      </CartHeader>
      <CartContainer>
        <ProductsContainer>
          {lineItems.map((lineItem) => {
            if (!['bundles', 'skus'].includes(lineItem.itemType)) {
              return null;
            }

            const isSubscription = lineItem.skuCode === SUMUP_ONE_SKU;

            return (
              <LineItem
                lineItem={lineItem}
                key={lineItem.id}
                removeFromCart={removeFromCart}
                isSubscriptionPlan={isSubscription}
              />
            );
          })}
        </ProductsContainer>

        <Hr />
        {hasSumUpOneSubscription && sumUpOneSubscription.monthlyCost && (
          <>
            <TextWrapper>
              <Body size="two" variant="highlight">
                {cartLabels.monthlyCost}
              </Body>
              <Body size="two" variant="highlight">
                {sumUpOneSubscription.monthlyCost}
              </Body>
            </TextWrapper>
            <Hr />
          </>
        )}

        <TextWrapper>
          <Body size="two">{cartLabels.delivery}</Body>
          <Body size="two">{order.shippingPrice}</Body>
        </TextWrapper>
        <GrandTotal>
          <Body size="two" variant="highlight">
            {cartLabels.totalCost}{' '}
            <SubtleText>({cartLabels.vatIncluded})</SubtleText>
          </Body>
          <Body size="two" variant="highlight">
            {order.totalPrice}
          </Body>
        </GrandTotal>
        <Link
          href={getStorefrontReviewCartLink(locale, order.id)}
          trackingId={''}
          trackingContentEntry={{}}
          onClick={() => {
            trackGoToCart({ order });
          }}
        >
          <ViewCartButton
            variant="primary"
            stretch
            isLoading={status !== 'idle'}
            loadingLabel={cartLabels.loadingLabel}
          >
            {cartLabels.viewCart}
          </ViewCartButton>
        </Link>
      </CartContainer>
    </>
  );
};
