import React, { Fragment, ReactNode } from 'react';
import mergeAll from 'lodash/fp/mergeAll';
import isEmpty from 'lodash/fp/isEmpty';
import Head from 'next/head';

import * as MetaService from './MetaService';
import * as ProductSchemaService from './ProductSchemaService';
import * as FAQSchemaService from './FAQSchemaService';
import PreloadMedia from './components/PreloadMedia';

import * as Url from '~/shared/services/url';
import { DEFAULT_LOCALE } from '~/shared/constants/locales';
import useFeatureFlag from '~/shared/hooks/use-feature-flag';
import useActiveCookieCategories from '~/shared/hooks/use-active-cookie-categories';
import * as ONE_TRUST from '~/shared/constants/onetrust';
import * as oneTrustScript from '~/shared/scripts/onetrust';
import * as OneTrustService from '~/shared/services/onetrust';
import * as gtmScript from '~/shared/scripts/gtm';
import {
  LIVEAGENT_ENABLE_FEATURE_FLAG,
  LIVEAGENT_INIT_SCRIPT_ID,
} from '~/shared/constants/salesforce-liveagent';
import { getLiveAgentInitScript } from '~/shared/scripts/salesforce-liveagent';
import * as SalesforceLiveagent from '~/shared/services/salesforce-liveagent';
import useOptimizelyData from '~/shared/services/optimizely/use-optimizely-data';
import useViewportName from '~/shared/hooks/use-viewport-name';
import {
  FeesType,
  PageType,
  ProductType,
  RouteType,
  SiteType,
} from '~/shared/types/shared';
import { RequestType } from '~/shared/providers/RequestContext/RequestContext';

type Props = {
  request?: Partial<RequestType>;
  site?: Partial<SiteType>;
  page?: Partial<PageType>;
  route?: Partial<RouteType>;
  localizedUrls?: object;
  fees?: Partial<FeesType>;
  products?: Record<string, ProductType>;
  children?: ReactNode;
  theme?: object;
  isCountrySelectionPage?: boolean;
  websitesWithHref?: Record<string, any>[];
};
/**
 * Add meta tags to the document head.
 */
function Meta({
  page = {},
  route = {},
  localizedUrls = {},
  request = {},
  site = {},
  products = {},
  fees = {},
  theme = {},
  children,
  isCountrySelectionPage = false,
  websitesWithHref = [],
}: Props) {
  const { meta = {} } = site;

  // MW-906: Meta description should not fallback to global default.
  const { metaTitle, metaDescription } = page;
  const { pathname, contentType, query = {} } = request;

  const viewportName = useViewportName();
  const { experiments } = useOptimizelyData();

  const useOneTrustTestScript = useFeatureFlag(
    ONE_TRUST.FEATURE_FLAGS.USE_TEST_SCRIPT,
  );
  const logOneTrustConsentChanges = useFeatureFlag(
    ONE_TRUST.FEATURE_FLAGS.LOG_CHANGES,
  );
  const enableSalesforceLiveagent = useFeatureFlag(
    LIVEAGENT_ENABLE_FEATURE_FLAG,
  );
  const activeCookieCategories = useActiveCookieCategories();
  const consentStatus = OneTrustService.getConsentStatus(
    activeCookieCategories,
  );

  const finalRoute = route || page.route;
  const finalLocalizedUrls = localizedUrls || page.localizedUrls;
  const { hrefLang } = finalRoute;
  const {
    twitter,
    siteName = 'SumUp',
    headline,
    socialSharingImage,
    facebookAppId,
    googlePlayStoreLink,
    facebookDomainVerification,
    googleSiteVerificationCode,
    googleMerchantCenterVerificationCode,
    updatedAt,
  } = mergeAll([{}, meta, page]);

  const image = MetaService.getSocialImageUrl(socialSharingImage);

  // canonical is usually the same page
  // but editors can specify a different one
  // in case we have for example one variation for mobile
  // or a page that is not translated
  // the main page is in a different locale
  const finalCanonicalUrl =
    route.canonicalUrl ||
    Url.format({
      protocol: 'https',
      host: request.host,
      pathname,
    });

  const title = MetaService.getTitle(metaTitle, headline, meta);
  const type = MetaService.getType(contentType);
  const robots = MetaService.getRobots(finalRoute);
  const adsBotIndexing = MetaService.getAdsBotIndexing(finalRoute);
  const socialTitle = MetaService.getSocialTitle(page, meta);
  const socialDescription = MetaService.getSocialDescription(page, meta);
  const productSchema = ProductSchemaService.getProductSchema({
    pageType: contentType,
    page,
    products,
  });
  const faqSchema = FAQSchemaService.generateSchema(
    page,
    site,
    products,
    fees,
    theme,
    request,
  );

  const { isSchemaEnabled, pageSchema } = MetaService.generatePageSchema({
    page,
    title,
    image,
  });

  const oneTrustSDKId = oneTrustScript.getSDKId(request, useOneTrustTestScript);

  const shouldShowOneTrustWidget =
    !(query as Record<string, any>).skipOneTrust && !__DEV__;

  const salesforceId = SalesforceLiveagent.getLocaleId(site.locale);

  const showDefaultHreflang =
    (!isEmpty(finalLocalizedUrls) && finalLocalizedUrls[DEFAULT_LOCALE]) ||
    isCountrySelectionPage;

  const shouldRenderHrefLang =
    hrefLang !== false &&
    !isEmpty(finalLocalizedUrls) &&
    Object.keys(finalLocalizedUrls).length > 1;

  return (
    <Head>
      <script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: gtmScript.setComplianceDefaults(consentStatus),
        }}
      ></script>

      {shouldShowOneTrustWidget && (
        <Fragment>
          <script
            src={ONE_TRUST.SDK_URL}
            type="text/javascript"
            // eslint-disable-next-line react/no-unknown-property
            charSet="UTF-8"
            data-document-language="true"
            data-domain-script={oneTrustSDKId}
          />
          <script
            type="text/javascript"
            dangerouslySetInnerHTML={{
              __html: oneTrustScript.getOptanonWrapper(
                site.locale,
                logOneTrustConsentChanges,
              ),
            }}
          />
        </Fragment>
      )}
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      {googleSiteVerificationCode && (
        <meta
          key="site-verification"
          name="google-site-verification"
          content={googleSiteVerificationCode}
        />
      )}
      {googleMerchantCenterVerificationCode && (
        <meta
          key="merchant-center"
          name="google-site-verification"
          content={googleMerchantCenterVerificationCode}
        />
      )}
      {facebookDomainVerification && (
        <meta
          name="facebook-domain-verification"
          content={facebookDomainVerification}
        />
      )}
      <title>{title}</title>

      <PreloadMedia
        sections={page.sections}
        experiments={experiments}
        viewportName={viewportName}
      />

      {metaDescription && <meta name="description" content={metaDescription} />}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content={`@${twitter}`} />
      <meta property="fb:app_id" content={facebookAppId} />
      <meta property="og:site_name" content={siteName} />
      <meta property="og:title" content={socialTitle} />
      <meta property="og:description" content={socialDescription} />
      <meta property="og:image" content={image} />
      <meta property="og:type" content={type} />
      <meta property="og:updated" content={updatedAt} />
      <meta property="og:url" content={finalCanonicalUrl} />
      <meta
        property="og:locale"
        content={(site.locale || '').replace('-', '_')}
      />
      {googlePlayStoreLink && (
        <meta name="google-play-app" content={googlePlayStoreLink} />
      )}
      {
        <>
          <meta name="robots" content={robots} />
          <meta name="AdsBot-Google" content={adsBotIndexing} />
        </>
      }
      {showDefaultHreflang ? (
        <link
          rel="alternate"
          hrefLang="x-default"
          href={'https://www.sumup.com/'}
        />
      ) : null}
      {shouldRenderHrefLang &&
        Object.keys(finalLocalizedUrls).map(
          (siteLocale) =>
            finalLocalizedUrls[siteLocale] && (
              <link
                data-selector="hreflang"
                rel="alternate"
                key={siteLocale}
                hrefLang={siteLocale.toLowerCase()}
                href={Url.format(finalLocalizedUrls[siteLocale])}
              />
            ),
        )}
      {isCountrySelectionPage &&
        websitesWithHref.map((websiteItem) => (
          <link
            rel="alternate"
            key={websiteItem.label}
            hrefLang={websiteItem.locale.toLowerCase()}
            href={Url.format(websiteItem.href)}
          />
        ))}

      <link rel="canonical" href={finalCanonicalUrl} />

      {children}

      {productSchema && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: productSchema,
          }}
        />
      )}
      {faqSchema && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: faqSchema,
          }}
        />
      )}
      {isSchemaEnabled && (
        <script
          type="application/ld+json"
          id="page-schema"
          data-selector="page-schema"
          dangerouslySetInnerHTML={{
            __html: pageSchema,
          }}
        />
      )}
      {enableSalesforceLiveagent && salesforceId && (
        <script
          defer
          id={LIVEAGENT_INIT_SCRIPT_ID}
          dangerouslySetInnerHTML={{
            __html: getLiveAgentInitScript(site.locale),
          }}
        />
      )}
    </Head>
  );
}

/**
 * @component
 */
export default Meta;
