import React, { FC, useMemo } from 'react';
import styled from '@emotion/styled';
import { Theme, css } from '@emotion/react';
import { Button } from '@sumup/circuit-ui';
import Tagger from '@elbwalker/tagger';

import MenuItem from '../../../MenuItem';
import { MenuItem as MenuItemType } from '../../../../interfaces';
import { DATA_SELECTOR } from '../../constants';
import ShoppingCart from '../../../ShoppingCart';

import Link from '~/shared/components/Link';
import dataSelector from '~/shared/util/data-selector';
import { NAVIGATION_EVENTS } from '~/shared/services/optimizely/navigation-revamp-experiment/constants';
import { ACTIONS, ENTITIES, TRIGGERS } from '~/shared/constants/tracking';
import {
  STOREFRONT_URL_DEV,
  STOREFRONT_URL_PROD,
} from '~/domains/shop/services/purchasable-client/constants';

const desktopContainerStyles = ({ theme }: { theme: Theme }) => css`
  display: none;

  ${theme.mq.mega} {
    display: flex;
    justify-content: space-between;
    align-content: center;
    grid-gap: ${theme.spacings.mega};
    border-left: 1px solid var(--cui-border-strong);
    padding-left: ${theme.spacings.giga};
  }
`;
const DesktopContainer = styled('div')(desktopContainerStyles);
const ActionsWrapper = styled('div')(
  () => css`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: var(--cui-spacings-giga);
  `,
);

const desktopMenuListStyles = ({ theme }: { theme: Theme }) => css`
  list-style: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: ${theme.spacings.mega};
`;
const DesktopMenuList = styled('ul')(desktopMenuListStyles);

interface DesktopTableOfContentsProps {
  items?: MenuItemType[];
  ctaItem?: MenuItemType;
  isSticky?: boolean;
  shopIntegration?: boolean;
}

export const DesktopTableOfContents: FC<DesktopTableOfContentsProps> = ({
  items = [],
  ctaItem,
  isSticky = false,
  shopIntegration = false,
}) => {
  const tagger = useMemo(() => {
    const taggerInfo = {
      action: ACTIONS.CLICK,
      entity: ENTITIES.NAV,
      trigger: TRIGGERS.CLICK,
    };
    const { action, entity, trigger } = taggerInfo;
    const t = Tagger();

    return {
      ...t.entity(entity),
      ...t.action(trigger, action),
    };
  }, []);
  const hasCtaUrl = !!(ctaItem?.label && ctaItem?.destination?.url);
  const hasShopUrlInCta = [STOREFRONT_URL_PROD, STOREFRONT_URL_DEV].some(
    (storefrontUrl) => ctaItem?.destination?.url?.includes(storefrontUrl),
  );
  const shouldShowCtaLink = shopIntegration
    ? hasCtaUrl && !hasShopUrlInCta
    : hasCtaUrl;

  return (
    <DesktopContainer>
      {items.length && (
        <DesktopMenuList>
          {items.map((item, index) => (
            <li key={`toc-item-${item.label}-${index}`}>
              <MenuItem
                {...item}
                optimizelyFullStackClickEvents={[
                  {
                    eventName: NAVIGATION_EVENTS.THIRD_LEVEL,
                    eventTags: { labels: item.label },
                  },
                ]}
                data-elbcontext={`component:${DATA_SELECTOR}`}
                data-elb="nav"
                data-elb-nav={`description:${item.label}@${DATA_SELECTOR}`}
                {...tagger}
              />
            </li>
          ))}
        </DesktopMenuList>
      )}

      <ActionsWrapper>
        {isSticky && shopIntegration && (
          <ShoppingCart addRightSpace={!shouldShowCtaLink} />
        )}

        {shouldShowCtaLink && (
          <Link
            href={ctaItem.destination?.url}
            trackingId=""
            trackingContentEntry={{}}
            data-selector={dataSelector('cta', DATA_SELECTOR)}
            optimizelyFullStackClickEvents={[
              {
                eventName: NAVIGATION_EVENTS.THIRD_LEVEL,
                eventTags: { labels: ctaItem.label },
              },
            ]}
          >
            <Button
              variant="primary"
              data-elbcontext={`component:${DATA_SELECTOR}`}
              data-elb="nav"
              data-elb-nav={`description:${ctaItem.label}@cta@${DATA_SELECTOR}`}
              {...tagger}
            >
              {ctaItem.label}
            </Button>
          </Link>
        )}
      </ActionsWrapper>
    </DesktopContainer>
  );
};
