import { FC } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { elb } from '@elbwalker/walker.js';

import * as inline from '~/shared/components/RichText/configs/inline';
import RichText from '~/shared/components/RichText';
import dataSelector from '~/shared/util/data-selector';
import * as Analytics from '~/shared/services/analytics';
import * as OptimizelyFullStack from '~/shared/services/optimizely/optimizely-browser-client';
import { sendNinetailedEvent } from '~/shared/services/ninetailed/events';

const DATA_SELECTOR = 'info_banner';

const wrapperBaseStyles = ({ theme }) => css`
  padding: ${theme.spacings.kilo};
  width: 100%;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--cui-fg-on-strong);
  margin-top: 0;
  transition: margin 0.3s;

  a,
  a:visited {
    border-bottom: 0;
    text-decoration: underline;
    font-weight: bold;
    color: var(--cui-fg-on-strong);
  }

  a:hover,
  a:visited:hover,
  a:visited:active {
    color: var(--cui-fg-on-strong-hovered);
    opacity: 0.8;
  }
`;

const wrapperIsClickableStyles = css`
  cursor: pointer;
`;

const wrapperBackgroundStyles = () => css`
  background-color: var(--cui-fg-accent);
`;

const wrapperStyles = (props) => css`
  ${wrapperBaseStyles(props)};
  ${props.isClickable && wrapperIsClickableStyles};
  ${wrapperBackgroundStyles()};
`;

const Wrapper = styled('div')(wrapperStyles);
export interface InfoBannerProps {
  id: string;
  name: string;
  contentType: string;
  text?: Document | string;
  isClickable?: boolean;
  clickableLink?: string;
  background?: string;
  trackingId?: string;
  optimizelyFullStackClickEvents?: string[];
}

export const InfoBanner: FC<InfoBannerProps> = ({
  id,
  name,
  contentType,
  text,
  isClickable,
  clickableLink,
  background,
  trackingId,
  optimizelyFullStackClickEvents,
}) => {
  const trackingContentEntry = {
    contentType,
    contentEntryName: name,
    contentEntryId: id,
  };

  const onClick = () => {
    if (isClickable) {
      Analytics.sendEvent({
        event: 'interaction',
        target: 'Mkt_Web',
        action: trackingId,
        destinationCategory: undefined,
        destinationUrl: clickableLink,
        ...trackingContentEntry,
      });
      sendNinetailedEvent(trackingId);

      elb('button clicked', {
        button_description: trackingId,
      });

      OptimizelyFullStack.trackEvents(optimizelyFullStackClickEvents);
      window.location.assign(clickableLink);
    }
  };

  return (
    <Wrapper
      isClickable={isClickable}
      data-selector={dataSelector('wrapper', DATA_SELECTOR)}
      onClick={onClick}
      background={background}
    >
      <RichText
        renderNode={inline.createRenderNode(inline.ONE, trackingContentEntry)}
        renderMark={inline.createRenderMark(inline.ONE)}
        richText={text}
      />
    </Wrapper>
  );
};
