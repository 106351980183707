import {
  PurchaseClient,
  COUNTRY_CODE_TO_MARKET_REF_DEV,
  COUNTRY_CODE_TO_MARKET_REF_PROD,
} from '@sumup/purchasable-sdk';
import getConfig from 'next/config';

import { STOREFRONT_URL_DEV, STOREFRONT_URL_PROD } from './constants';

import {
  NextJsConfig,
  PublicRuntimeConifg,
} from '~/shared/types/nextjs-config';
import * as ENVIRONMENTS from '~/shared/constants/environments';

export type PurchaseClientType = ReturnType<typeof PurchaseClient>;

const clientCache: Record<string, PurchaseClientType> = {};

function getMarketList(): Record<string, string> {
  const { publicRuntimeConfig = {} } = getConfig() as NextJsConfig;
  const { environment } = publicRuntimeConfig as PublicRuntimeConifg;
  const getMarketIdList =
    environment !== ENVIRONMENTS.PRODUCTION &&
    environment !== ENVIRONMENTS.THETA
      ? COUNTRY_CODE_TO_MARKET_REF_DEV
      : COUNTRY_CODE_TO_MARKET_REF_PROD;

  return getMarketIdList;
}

const markets = getMarketList();

export const getClient = (locale: string): PurchaseClientType => {
  const countryCode = locale.split('-')[1].toUpperCase();
  const marketReference = markets[countryCode];

  if (!marketReference) {
    return undefined;
  }

  if (!clientCache[locale]) {
    clientCache[locale] = PurchaseClient({
      commerceLayer: {
        marketReference,
        clientId: process.env.NEXT_PUBLIC_COMMERCE_LAYER_CLIENT_ID,
      },
    });
  }

  return clientCache[locale];
};

export const getStorefrontReviewCartLink = (
  locale: string,
  orderId: string,
) => {
  const { publicRuntimeConfig = {} } = getConfig() as NextJsConfig;
  const { environment } = publicRuntimeConfig as PublicRuntimeConifg;
  const storefrontUrl =
    environment !== ENVIRONMENTS.PRODUCTION &&
    environment !== ENVIRONMENTS.THETA
      ? STOREFRONT_URL_DEV
      : STOREFRONT_URL_PROD;
  const CATALOG = '/website';
  return `${storefrontUrl}/${locale?.toLowerCase()}${CATALOG}/cart/${orderId}`;
};
