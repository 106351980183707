import { zIndex, spacings, mq } from './themes';

// hide onetrust widget bottom in the left-bottom corner of the page
const oneTrustStyles = `
#ot-sdk-btn-floating.ot-floating-button {
  display: none !important;
}`;

// adjustments to make it work with website navigation
// https://circuit.sumup.com/?path=/docs/notification-notificationtoast--docs
const circuitUINotificationToastOverwrites = `
body {
  --cui-z-index-toast: calc(${zIndex.navigation} + 1);
}
.cui-website-toast {
  top: ${spacings.nav.desktop};
  width: 280px;
}
.cui-website-toast-with-info-banner {
  top: calc(${spacings.nav.desktop} + ${spacings.infoBanner.desktop});
}
${mq.untilKilo} {
  .cui-website-toast {
    top: ${spacings.nav.mobile};
    width: auto;
    right: 0;

  }
  .cui-website-toast-with-info-banner {
    top: calc(${spacings.nav.mobile} + ${spacings.infoBanner.mobile});
    width: auto;
    right: 0;
  }
}
`;

export default ({ theme }) => `
body {
  position: relative;
  color: var(--cui-fg-normal);
  background-color: var(--cui-bg-normal);
}

img {
  display: block;
  width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: ${theme.fontWeight.bold};
  hyphens: ${theme.hyphens.headings.default};

  ${theme.mq.untilKilo} {
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: ${theme.hyphens.headings.untilKilo};
  }
}

h1,
h2 {
  line-height: 1.2em;
}

h3 {
  line-height: 30px;
}

h4,
h5,
h6 {
  line-height: 24px;
}

a {
  color: inherit;
  text-decoration: none;
  transition: all 0.2s;
}

.align--left {
  text-align: left;
}

.align--center {
  text-align: center;
}

.align--right {
  text-align: right;
}

p,
a,
ul,
li,
ol,
div,
span {
  hyphens: ${theme.hyphens.text.default};
}

[data-color-scheme="dark"] {
  --cui-bg-normal: #000;
  color: var(--cui-fg-normal);
}

${oneTrustStyles}
${circuitUINotificationToastOverwrites}
`;
