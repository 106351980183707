import React, { FC, useMemo } from 'react';
import { SubHeadline, Hr } from '@sumup/circuit-ui';
import styled from '@emotion/styled';
import { Theme, css } from '@emotion/react';
import Tagger from '@elbwalker/tagger';

import { MenuItem } from '../../../MenuItem/MenuItem';

import { MenuItemGroup } from '~/shared/components/HeaderNavigation/interfaces';
import dataSelector from '~/shared/util/data-selector';
import { NAVIGATION_EVENTS } from '~/shared/services/optimizely/navigation-revamp-experiment/constants';
import { ACTIONS, ENTITIES, TRIGGERS } from '~/shared/constants/tracking';
import {
  DATA_SELECTOR,
  GROUPS_LIST_ITEM_SELECTOR,
  LINK_SELECTOR,
} from '~/shared/components/HeaderNavigation/constants';
import { useExpanded } from '~/shared/components/HeaderNavigation/hooks/use-expanded';

const GroupColumn = styled('div')();

const groupHeadlineStyles = (_: { isExpanded?: boolean }) => css`
  text-transform: uppercase;
  color: var(--cui-fg-subtle);
`;

const groupHeadlineExpandedStyles = ({
  isExpanded,
}: {
  isExpanded?: boolean;
}) =>
  isExpanded &&
  css`
    color: var(--cui-fg-normal);
  `;

const GroupHeadline = styled(SubHeadline)(
  groupHeadlineStyles,
  groupHeadlineExpandedStyles,
);

const groupLinksListStyles = ({
  theme,
  isExpanded,
}: {
  theme?: Theme;
  isExpanded: boolean;
}) => css`
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacings.mega};
  overflow: hidden;
  height: 0;
  display: none;

  ${isExpanded &&
  ` 
    display: flex;
    height: 100%;
    margin-top: ${theme.spacings.giga};
  `}

  ${theme.mq.mega} {
    display: flex;
    height: 100%;
    margin-top: ${theme.spacings.giga};
  }
`;

const GroupLinksList = styled('ul')(groupLinksListStyles);

const groupLinksListItemStyles = ({ theme }: { theme: Theme }) => css`
  padding: 0 ${theme.spacings.mega};

  ${theme.mq.mega} {
    padding: 0;
  }
`;

const GroupLinksListItem = styled('li')(groupLinksListItemStyles);

const hrStyles = ({ theme }: { theme?: Theme }) => css`
  :last-of-type {
    display: none;
  }

  ${theme.mq.mega} {
    display: none;
  }
`;

const StyledHr = styled(Hr)(hrStyles);

const groupHeadlineWrapperStyles = ({
  theme,
  isExpanded,
}: {
  theme?: Theme;
  isExpanded?: boolean;
}) => css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  button {
    text-transform: uppercase;
    color: ${isExpanded ? 'var(--cui-fg-normal)' : 'var(--cui-fg-subtle)'};
    font-size: var(--cui-typography-sub-headline-font-size);
    font-weight: var(--cui-font-weight-bold);
    text-decoration: none;

    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
    }

    svg {
      color: var(--cui-fg-normal);
    }
  }

  ${theme.mq.mega} {
    cursor: auto;
    pointer-events: none;
  }
`;

const GroupHeadlineWrapper = styled('div')(groupHeadlineWrapperStyles);

export interface GroupsMenuItemProps {
  group: MenuItemGroup;
  isMobile: boolean;
  isOpen: boolean;
  onGroupItemClick: () => void;
}

export const GroupsMenuItem: FC<GroupsMenuItemProps> = ({
  group,
  isMobile,
  onGroupItemClick,
  isOpen,
}) => {
  const { expanded: isExpanded, setExpanded } = useExpanded({
    isOpen,
    isMobile,
    group,
  });

  const tagger = useMemo(() => {
    const taggerInfo = {
      action: ACTIONS.CLICK,
      entity: ENTITIES.NAV,
      trigger: TRIGGERS.CLICK,
    };
    const { action, entity, trigger } = taggerInfo;
    const t = Tagger();

    return {
      ...t.entity(entity),
      ...t.action(trigger, action),
    };
  }, []);
  const isHidden = !(isExpanded && isMobile);

  const handleGroupClick = () => {
    setExpanded(!isExpanded);
  };

  return (
    <>
      <GroupColumn>
        {group.label && (
          <GroupHeadlineWrapper isExpanded={isExpanded}>
            {isMobile ? (
              <MenuItem
                {...group}
                showArrowIcon={isMobile}
                rotateArrowIcon={isMobile && isExpanded}
                isCurrent={isMobile && isExpanded}
                onClick={handleGroupClick}
                aria-haspopup={isMobile}
                aria-expanded={isMobile && isExpanded}
                highlighted
                shouldTrack={false}
              />
            ) : (
              <GroupHeadline as="h3">{group.label}</GroupHeadline>
            )}
          </GroupHeadlineWrapper>
        )}

        <GroupLinksList
          isExpanded={isExpanded}
          aria-hidden={isMobile && !isExpanded}
          hidden={isHidden}
          aria-haspopup={isMobile}
          aria-expanded={!isMobile || isExpanded}
        >
          {group.items?.map((item, itemIndex) => (
            <GroupLinksListItem key={`${item.label}-${itemIndex}`}>
              <MenuItem
                {...item}
                onClick={onGroupItemClick}
                data-selector={dataSelector(
                  LINK_SELECTOR,
                  GROUPS_LIST_ITEM_SELECTOR,
                  DATA_SELECTOR,
                )}
                optimizelyFullStackClickEvents={[
                  {
                    eventName: NAVIGATION_EVENTS.SECOND_LEVEL,
                    eventTags: { label: item.label },
                  },
                ]}
                data-elbcontext={`component:${DATA_SELECTOR}`}
                data-elb="nav"
                data-elb-nav={`description:${item.label}@${GROUPS_LIST_ITEM_SELECTOR}@${DATA_SELECTOR}`}
                {...tagger}
              />
            </GroupLinksListItem>
          ))}
        </GroupLinksList>
      </GroupColumn>
      {isMobile && <StyledHr />}
    </>
  );
};
