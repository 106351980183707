import pathToRegexp from 'path-to-regexp';

import {
  MANUAL_AIR_PATH,
  MANUAL_3G_PATH,
  PLUS_PATH,
  FEES_PATH,
  CONTACT_PATH,
} from '~/domains/product-manuals/constants/pathnames';
import {
  CAREERS_PATH,
  CONTENT_PREVIEW_PATH,
} from '~/shared/constants/pathnames';
import { addTrailingSlash, addLeadingSlash } from '~/shared/services/url';

export const NON_LOCALIZED_PATHNAMES = [
  `${CAREERS_PATH}*`,
  `${CONTENT_PREVIEW_PATH}*`,
  MANUAL_AIR_PATH,
  MANUAL_3G_PATH,
  PLUS_PATH,
  FEES_PATH,
  CONTACT_PATH,
];

export const isNonLocalizedPathname = (pathname: string = ''): boolean => {
  const targetPathname = addTrailingSlash(addLeadingSlash(pathname));
  return NON_LOCALIZED_PATHNAMES.some((nonLocalizedPathname) => {
    const regexp = pathToRegexp(nonLocalizedPathname);
    const match = regexp.test(targetPathname);
    return match;
  });
};
