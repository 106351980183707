import React, { FC, useMemo } from 'react';
import styled from '@emotion/styled';
import { Theme, css } from '@emotion/react';
import Tagger from '@elbwalker/tagger';

import MenuItem from '../../../MenuItem';
import GroupsMenu from '../../../GroupsMenu';

import { MenuItem as MenuItemType } from '~/shared/components/HeaderNavigation/interfaces';
import dataSelector from '~/shared/util/data-selector';
import {
  DATA_SELECTOR,
  LINK_SELECTOR,
  LIST_ITEM_SELECTOR,
} from '~/shared/components/HeaderNavigation/constants';
import { useExpanded } from '~/shared/components/HeaderNavigation/hooks/use-expanded';
import { ACTIONS, ENTITIES, TRIGGERS } from '~/shared/constants/tracking';

const listItemStyles = ({
  theme,
  isExpanded,
}: {
  theme?: Theme;
  isExpanded: boolean;
}) => css`
  position: relative;
  background-color: var(--cui-bg-subtle);
  border-radius: ${theme.borderRadius.byte};
  padding: ${theme.spacings.kilo} ${theme.spacings.giga};
  height: ${theme.spacings.exa};

  ${isExpanded &&
  css`
    height: auto;
  `}
`;

const currentListItemStyles = ({
  theme,
  ...props
}: {
  theme?: Theme;
  isExpanded: boolean;
}) =>
  props['aria-current'] === 'page' &&
  css`
    background-color: var(--cui-bg-highlight);
  `;

const StyledListItem = styled('li')(listItemStyles, currentListItemStyles);

const GroupsMenuDropdownStyles = ({
  isExpanded,
}: {
  theme?: Theme;
  isExpanded: boolean;
}) => css`
  height: 0;
  overflow: hidden;

  ${isExpanded &&
  `
    height: 100%;
  `};
`;

const GroupsMenuDropdown = styled('div')(GroupsMenuDropdownStyles);

export interface MobileMenuProps {
  item: MenuItemType;
  isOpen: boolean;
  onLinkClick: (_hasGroups: boolean) => void;
  optimizelyFullStackClickEvents?: any[];
}

export const MobileMenuItem: FC<MobileMenuProps> = ({
  item,
  onLinkClick,
  optimizelyFullStackClickEvents,
  isOpen,
}) => {
  const { expanded: isExpanded, setExpanded } = useExpanded({
    isOpen,
    isMobile: true,
    item,
  });
  const tagger = useMemo(() => {
    const taggerInfo = {
      action: ACTIONS.CLICK,
      entity: ENTITIES.NAV,
      trigger: TRIGGERS.CLICK,
    };
    const { action, entity, trigger } = taggerInfo;
    const t = Tagger();

    return {
      ...t.entity(entity),
      ...t.action(trigger, action),
    };
  }, []);

  const hasGroups = !!item.menuItemGroups?.length;

  const handleLinkClick = (isLinkWithGroups) => () => {
    setExpanded(!isExpanded);
    onLinkClick(isLinkWithGroups);
  };

  return (
    <StyledListItem
      data-selector={dataSelector(LIST_ITEM_SELECTOR, DATA_SELECTOR, 'mobile')}
      isExpanded={isExpanded}
    >
      <MenuItem
        {...item}
        data-selector={dataSelector(
          LINK_SELECTOR,
          LIST_ITEM_SELECTOR,
          DATA_SELECTOR,
          'mobile',
        )}
        showArrowIcon={hasGroups}
        rotateArrowIcon={isExpanded}
        isCurrent={hasGroups && isExpanded}
        onClick={handleLinkClick(hasGroups)}
        aria-haspopup={hasGroups}
        aria-expanded={isExpanded}
        highlighted
        optimizelyFullStackClickEvents={optimizelyFullStackClickEvents}
        shouldTrack={!hasGroups}
        data-elbcontext={`component:${DATA_SELECTOR}`}
        data-elb="nav"
        data-elb-nav={`description:${item.label}@${LIST_ITEM_SELECTOR}@${DATA_SELECTOR}`}
        {...tagger}
      />
      {hasGroups && (
        <GroupsMenuDropdown
          aria-hidden={!isExpanded}
          aria-expanded={isExpanded}
          isExpanded={isExpanded}
        >
          <GroupsMenu
            isOpen={isOpen}
            description={item.description}
            groups={item.menuItemGroups}
            onGroupItemClick={handleLinkClick(false)}
            isMobile
          />
        </GroupsMenuDropdown>
      )}
    </StyledListItem>
  );
};
