import isEmpty from 'lodash/fp/isEmpty';
import includes from 'lodash/fp/includes';
import get from 'lodash/fp/get';

import { RouteType } from '~/shared/types/shared';
import formatContentfulUrl from '~/shared/util/format-contentful-url';

export function getRobots(route: Partial<RouteType> = {}) {
  if (isEmpty(route)) {
    return 'index, follow';
  }

  return [
    route.index ? 'index' : 'noindex',
    route.follow ? 'follow' : 'nofollow',
  ].join(', ');
}

export function getAdsBotIndexing(route: Partial<RouteType> = {}) {
  if (isEmpty(route)) {
    return 'index';
  }

  return route.adsBotIndex ? 'index' : 'noindex';
}

export function getType(contentType) {
  return includes('sbg', contentType) ? 'article' : 'website';
}

function getSocialTag(tag, fallbackTag, page = {}, meta = {}) {
  return page[tag] || page[fallbackTag] || meta[tag] || meta[fallbackTag];
}

export function getSocialTitle(page, meta) {
  return getSocialTag('socialSharingTitle', 'metaTitle', page, meta);
}

export function getSocialDescription(page, meta) {
  return getSocialTag(
    'socialSharingDescription',
    'metaDescription',
    page,
    meta,
  );
}

// Whatsapp used to filter out all the images that are bigger than
// 300kb, although they loosed a little the limits overtime it still
// can sometimes not work: https://stackoverflow.com/questions/19778620/provide-an-image-for-whatsapp-link-sharing
const WHATSAPP_LIMIT = 300000;

export function getSocialImageUrl(image) {
  const imageUrl = get('file.url', image);
  const imageSize = get('file.details.size', image);

  return imageSize <= WHATSAPP_LIMIT
    ? formatContentfulUrl(imageUrl)
    : formatContentfulUrl(imageUrl, { w: 600 });
}

export function getTitle(metaTitle, headline, meta: any = {}) {
  return metaTitle || headline || meta.metaTitle || 'SumUp';
}

export function generatePageSchema({ page, title, image }) {
  const markupSchemaType = get('markupSchemaType', page);
  const markupSchema = get('markupSchema', page);

  if (!markupSchemaType || !markupSchema) {
    return { isSchemaEnabled: false };
  }

  if (
    Object.keys(markupSchema).length === 0 ||
    !Object.keys(markupSchema).every((key) => !!page.markupSchema[key])
  ) {
    return { isSchemaEnabled: false };
  }

  return {
    isSchemaEnabled: true,
    pageSchema: JSON.stringify({
      '@context': 'https://schema.org/',
      '@type': page.markupSchemaType,
      'name': title,
      image,
      'dateModified': page.updatedAt,
      'datePublished': page.createdAt,
      ...page.markupSchema,
    }),
  };
}
