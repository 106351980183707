/* eslint-disable react/display-name */
import React, { useEffect } from 'react';
import get from 'lodash/fp/get';

import Fee from '../Fee';

import { VARIABLES } from './constants';

import Price from '~/shared/components/Price';
import Anchor from '~/shared/components/Anchor';
import { initOneTrustCookieList } from '~/shared/services/onetrust';

export function getProduct(context = {}) {
  return get(['products', context.product?.productId], context) || {};
}

const feeHandler = (context, feeProp) => {
  const fee = get(['fees', feeProp], context);
  const promotionalFee = get(['globalVariable', 'value'], context);
  if (!fee) {
    return '';
  }

  return <Fee promotionalFee={promotionalFee}>{fee}</Fee>;
};

const OneTrustCookieList = () => {
  useEffect(() => {
    initOneTrustCookieList();
  }, []);
  return <div id="ot-sdk-cookie-policy" />;
};

export const handlers = {
  [VARIABLES.PRICE]: (context) => <Price product={getProduct(context)} />,
  [VARIABLES.ORIGINAL_PRICE]: (context) => {
    const product = getProduct(context);
    const originalPriceProperties = {
      ...product,
      hasPromo: false,
      asterisk: '',
    };

    return <Price product={originalPriceProperties} />;
  },
  [VARIABLES.EXPIRY_DATE]: (context) =>
    getProduct(context).expirationDate || '',
  [VARIABLES.CREDIT_CARD_FEE]: (context) =>
    feeHandler(context, 'creditCardFee'),
  [VARIABLES.DEBIT_CARD_FEE]: (context) => feeHandler(context, 'debitCardFee'),
  [VARIABLES.UNIVERSAL_FEE]: (context) => feeHandler(context, 'universalFee'),
  [VARIABLES.MONTHLY_FEE]: (context) => feeHandler(context, 'monthlyCost'),
  [VARIABLES.SUPPORT_EMAIL]: (context) => {
    const supportEmail = get(['site', 'supportEmail'], context);
    if (!supportEmail) {
      return '';
    }
    return <Anchor href={`mailto:${supportEmail}`}>{supportEmail}</Anchor>;
  },
  [VARIABLES.SUPPORT_PHONE]: (context) => {
    const supportPhone = get(['site', 'supportPhone'], context);
    if (!supportPhone) {
      return '';
    }
    return <Anchor href={`tel:${supportPhone}`}>{supportPhone}</Anchor>;
  },
  [VARIABLES.SUPPORT_HOURS]: (context) =>
    get(['site', 'supportHours'], context) || '',
  [VARIABLES.PAYOUT_TIME]: (context) =>
    get(['site', 'payoutTime'], context) || '',
  [VARIABLES.GLOBAL_VARIABLE]: (context) =>
    get(['globalVariable', 'value'], context) || '',
  [VARIABLES.QUERY_PARAMETER]: (context) => {
    const paramName = get('queryParameterName', context) || '';
    const defaultValue = get('queryParameterDefaultValue', context) || '';
    const value = get(['router', 'query', paramName], context) || '';

    return value || defaultValue;
  },
  [VARIABLES.ONETRUST_COOKIE_LIST]: () => <OneTrustCookieList />,
};
