import React, { FC, useMemo, useState, useRef } from 'react';
import styled from '@emotion/styled';
import { Theme, css } from '@emotion/react';
import { clearBodyLocks } from 'tua-body-scroll-lock';

import { HeaderNavigation as HeaderNavigationType } from './interfaces';
import BrandArea from './components/BrandArea';
import DesktopMenu from './components/DesktopMenu';
import TableOfContentsMenu from './components/TableOfContentsMenu';
import InfoBanner from './components/InfoBanner';
import MobileMenu from './components/MobileMenu';
import MobileStickyCta from './components/MobileStickyCta';
import { SCROLL_THRESHOLD } from './constants';

import dataSelector from '~/shared/util/data-selector';
import { useScrolledPastThreshold } from '~/shared/hooks/use-scroll';
import useComponentSize from '~/shared/hooks/use-component-size';
import SideBarNavigation from '~/shared/components/SideBarNavigation';
import { VARIANTS } from '~/shared/components/SideBarNavigation/constants';

const headerBaseStyles = ({
  theme,
  isSticky,
  isFixed,
  fixedOffset,
}: {
  theme?: Theme;
  isSticky?: boolean;
  isFixed?: boolean;
  fixedOffset?: number;
}) => css`
  position: relative;
  top: 0;
  right: 0;
  left: 0;
  z-index: ${theme.zIndex.navigation};
  background-color: var(--cui-bg-normal);
  border-bottom: ${theme.borderWidth.kilo} solid var(--cui-border-subtle);

  ${isFixed &&
  `
  position: fixed;

  + div {
    margin-top: ${fixedOffset}px;
  }
`}

  ${theme.mq.untilMega} {
    box-shadow: 0 1px 2px 0 rgba(12, 15, 20, 0.07);
  }

  ${isSticky &&
  !isFixed &&
  `
  position: sticky;
`}

  ${isSticky &&
  `
  animation-name: navslidein;
  animation-duration: 0.3s;
  @keyframes navslidein {
    from {
      transform: translateY(-100%);
    }
    to {
      translateY(0);
    }
  }
`}
`;

const Header = styled('header')(headerBaseStyles);

const wrapperStyles = ({ theme }: { theme: Theme }) => css`
  display: flex;
  align-items: center;
  position: relative;
  z-index: ${theme.zIndex.navigation};
  height: ${theme.spacings.nav.mobile};
  padding: 0 ${theme.spacings.giga};
  gap: min(${theme.spacings.giga}, 1vw);
  justify-content: space-between;

  @media (min-width: calc(${theme.maxWidth} + 1px)) {
    padding: 0;
    margin: 0 auto;
    height: ${theme.spacings.nav.desktop};
    width: ${theme.maxWidth};
    gap: ${theme.spacings.giga};
  }
`;

const Wrapper = styled('div')(wrapperStyles);

const sideBarWrapperStyles = ({ theme }: { theme: Theme }) => css`
  display: block;
  margin: 0 auto;
  max-width: ${theme.maxWidth};
  position: relative;
  width: 100%;
  height: ${theme.spacings.zetta};

  ${theme.mq.mega} {
    display: none;
  }
`;

const SideBarWrapper = styled('div')(sideBarWrapperStyles);

const sideBarBaseStyles = ({ theme }: { theme: Theme }) => css`
  position: absolute;
  overflow-y: scroll;
  z-index: calc(${theme.zIndex.navigation} - 1);
  width: 100%;
  box-shadow: 0 1px 2px 0 rgba(12, 15, 20, 0.07);

  h4 {
    border-bottom-color: var(--cui-border-subtle);
    padding: 0 ${theme.spacings.giga};
  }

  ul {
    background-color: var(--cui-bg-normal);
    padding: 0 ${theme.spacings.giga};
  }
`;

const SideBar = styled('div')(sideBarBaseStyles);

export interface HeaderNavigationProps extends HeaderNavigationType {
  shopIntegration?: boolean;
}

export const HeaderNavigation: FC<HeaderNavigationProps> = ({
  navigation,
  secondaryLogo,
  tableOfContents,
  infoBanner,
  sideBarNavigation,
  hideInfoBanner = false,
  shopIntegration = false,
}) => {
  const headerRef = useRef(null);
  const headerSize: { width: number; height: number } =
    useComponentSize(headerRef);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isToCMobileMenuOpen, setToCMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setToCMobileMenuOpen(false);
    setMobileMenuOpen(!isMobileMenuOpen);
  };
  const toggleToCMobileMenu = () => {
    setMobileMenuOpen(false);
    setToCMobileMenuOpen(!isToCMobileMenuOpen);
  };
  const handleBrandClick = () => {
    clearBodyLocks();
    if (isMobileMenuOpen) {
      setMobileMenuOpen(false);
    }
    if (isToCMobileMenuOpen) {
      setToCMobileMenuOpen(false);
    }
  };

  const trackingContentEntry = useMemo(
    () => ({
      contentType: navigation.contentType,
      contentEntryName: navigation.name,
      contentEntryId: navigation.id,
    }),
    [navigation?.contentType, navigation?.id, navigation?.name],
  );

  const isSticky = useScrolledPastThreshold(SCROLL_THRESHOLD);
  const hasInfoBanner = !!infoBanner?.name && !isSticky && !hideInfoBanner;
  const hasTableOfContents = !!tableOfContents?.label;
  const hasSideBarNavigation = !!sideBarNavigation?.navigationLinks?.length;

  const { shop, help, login, primaryStickyCta, secondaryStickyCta } =
    navigation;
  const staticLinks = { shop, help, login };
  const stickyLinks = { primaryStickyCta, secondaryStickyCta };

  const tableOfContentsCta = tableOfContents?.ctaItem;
  const mobileStickyCtaItem =
    tableOfContentsCta?.label && tableOfContentsCta?.destination?.url
      ? tableOfContentsCta
      : primaryStickyCta;

  return (
    <>
      <Header
        ref={headerRef}
        data-selector={dataSelector('header', 'navigation')}
        isSticky={isSticky && !hasTableOfContents}
        isFixed={isMobileMenuOpen}
        fixedOffset={headerSize.height}
      >
        {hasInfoBanner && !isMobileMenuOpen && <InfoBanner {...infoBanner} />}
        <Wrapper>
          <BrandArea
            primaryLogoLinksToHomepage
            trackingContentEntry={trackingContentEntry}
            secondaryLogo={secondaryLogo}
            onClick={handleBrandClick}
          />
          <DesktopMenu
            items={navigation?.items}
            isSticky={isSticky}
            staticLinks={staticLinks}
            stickyLinks={stickyLinks}
            shopIntegration={shopIntegration}
          />
          <MobileMenu
            items={navigation?.items}
            staticLinks={staticLinks}
            isOpen={isMobileMenuOpen}
            toggleMenu={toggleMobileMenu}
            shopIntegration={shopIntegration}
          />
        </Wrapper>
      </Header>

      {hasTableOfContents && !hasSideBarNavigation && (
        <TableOfContentsMenu
          {...tableOfContents}
          isSticky={isSticky}
          logoTrackingContentEntry={trackingContentEntry}
          handleBrandClick={handleBrandClick}
          isMobileMenuOpen={isToCMobileMenuOpen}
          toggleMobileMenu={toggleToCMobileMenu}
          shopIntegration={shopIntegration}
        />
      )}
      {hasSideBarNavigation && (
        <SideBarWrapper>
          <SideBar>
            <SideBarNavigation
              variant={VARIANTS.COLLAPSABLE}
              {...sideBarNavigation}
            />
          </SideBar>
        </SideBarWrapper>
      )}

      <MobileStickyCta
        isSticky={isSticky}
        ctaItem={mobileStickyCtaItem}
        shopIntegration={shopIntegration}
      />
    </>
  );
};
