import React, { ReactNode, createContext } from 'react';
import merge from 'lodash/fp/merge';

import * as ActionTypes from './actionTypes';

type PageProviderProps = {
  children: ReactNode;
  page?: Record<string, any>;
};

/**
 * Passes down page data of the current page
 */
const PageContext = createContext({});

const initialState = {
  previousRoute: {},
  previousSections: [],
};

export function getSectionsIds(sections = []) {
  return sections.map(({ id, contentType }) => ({ id, contentType }));
}

export function reducer(store, action) {
  switch (action.type) {
    case ActionTypes.SET_PAGE:
      return {
        previousRoute: { ...store.route },
        previousSections: getSectionsIds(store.sections),
        ...action.payload,
      };
    default:
      return store;
  }
}

function PageProvider({ children, page }: PageProviderProps) {
  const [store, dispatch] = React.useReducer(
    reducer,
    merge(initialState, page),
  );

  React.useEffect(() => {
    if (page) {
      dispatch({ type: ActionTypes.SET_PAGE, payload: page });
    }
  }, [dispatch, page]);

  return <PageContext.Provider value={store}>{children}</PageContext.Provider>;
}

export { PageContext, PageProvider };
